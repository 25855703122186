import React, { useEffect, useState } from "react";
import {
  Table,
  Modal,
  Button,
  Row,
  Col,
  Pagination,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import {
  deleteTeachingStudent,
  fetchTeachingStudents,
  updateTeachingStudent,
} from "../../Redux/teachingStudents/teachingStudentSlice";
import { fetchLevelsByFormation } from "../../Redux/levels/levelsSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudents } from "../../Redux/Etudent/studentsSlice";
import { determineChoice } from "../../Redux/choix/teachingChoiceSlice";
import { useDebounce } from "use-debounce";
import { useParams } from "react-router-dom";

const IndividualTable = ({
  selectedYear,
  handlePageChange,
  activeTab
}) => {
  const { formationId } = useParams();

  const dispatch = useDispatch();
  const [currentTeachingStudent, setCurrentTeachingStudent] = useState(null);
  const [showTeachingStudentDeleteModal, setShowTeachingStudentDeleteModal] =
    useState(false);
  const [studentSearchTerm] = useState("");
  const [debouncedStudentSearchTerm] = useDebounce(studentSearchTerm, 600);

  const [formData] = useState({
    formation_id: "",
    student_id: "",
    level_id: "",
    teacher_id: "",
    choice_id: "",
  });

  const { teachingStudents, totalTeachingStudents, currentPage, pageSize } =
  useSelector((state) => state.teachingStudents);


  const totalPages = Math.ceil(totalTeachingStudents / pageSize);

  
  useEffect(() => {
    if (activeTab === "indvidual" && formationId) {
      const fetchTeachingStudentsData = async () => {
        try {
          await dispatch(
            fetchTeachingStudents({ page: currentPage, pageSize , formationId })
          ).unwrap();
          await dispatch(fetchLevelsByFormation(formationId)).unwrap();
        } catch (err) {
        }
      };
      fetchTeachingStudentsData();
    }
  }, [formationId, activeTab, dispatch, currentPage, pageSize]);
  // Fetch students effect
  useEffect(() => {
    if (selectedYear) {
      dispatch(
        fetchStudents({
          yearId: selectedYear,
          searchTerm: debouncedStudentSearchTerm,
        })
      );
    }
  }, [debouncedStudentSearchTerm, dispatch, selectedYear]);


  useEffect(() => {
    const determineChoiceData = async () => {
      try {
        if (formationId) {
          await dispatch(
            determineChoice({ formationId, levelId: formData.level_id })
          ).unwrap();
        }
      } catch (err) {
      }
    };

    determineChoiceData();
  }, [formationId, formData.level_id, dispatch]);

  const confirmTeachingStudentDelete = async () => {
    if (currentTeachingStudent) {
      await dispatch(deleteTeachingStudent(currentTeachingStudent.id)).unwrap();
      await dispatch(fetchTeachingStudents({ page: currentPage, pageSize, formationId })).unwrap();
      toast.success("Étudiant à enseigner supprimé avec succès!");
    }
    handleCloseDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    setShowTeachingStudentDeleteModal(false);
    setCurrentTeachingStudent(null);
  };

  const handleCheckboxChange = async (teaching) => {
    const updatedActiveStatus = !teaching.active;
  
    // const updatedTeaching = { ...teaching, active: updatedActiveStatus };
  
    try {
      await dispatch(
        updateTeachingStudent({
          id: teaching.id,
          studentData: { active: updatedActiveStatus },
        })
      ).unwrap();
  
      dispatch(fetchTeachingStudents({ formationId }));
      toast.success(
        `Étudiant à enseigner ${updatedActiveStatus ? "activé" : "désactivé"} avec succès!`
      );
    } catch (error) {
      toast.error("Échec de la mise à jour du statut actif.");
    }
  };
   
  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };


  return (
    <>
      <div className="table-responsive-etud">
        <Table hover size="lg" responsive className="mt-3" bordered>
          <thead>
            <tr className="text-center">
              <th>N</th>
              <th>Étudiant</th>
              <th>Niveau</th>
              <th>Prof</th>
              <th>Choix Méthode</th>
              <th>Durée Formation</th>
              <th>Nbr Séance par Semaine</th>
              <th>Durée Séance</th>
              <th>Prix</th>
              <th>Actif</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {teachingStudents.map((teaching, index) => (
              <tr key={teaching.id} className="text-center">
                <td>{index + 1}</td>
                <td>
                  {capitalizeFirstLetter(teaching.student?.nom) + " " + capitalizeFirstLetter(teaching.student?.prenom)}
                </td>
                <td>{teaching.level?.name?.toUpperCase() || "Non existant"}</td> 
                <td>
                  {capitalizeFirstLetter(teaching.teacher?.nom) + " " + capitalizeFirstLetter(teaching.teacher?.prenom)}
                </td>
                <td>
                  {teaching.choice?.teaching_method === "At home"
                    ? "À Domicile"
                    : teaching.choice?.teaching_method === "Group"
                    ? "Groupe"
                    : teaching.choice?.teaching_method === "Individual"
                    ? "Individuel"
                    : teaching.choice?.teaching_method}
                </td>
                <td>
                {teaching.choice?.formation_duration === 0
                      ? "Par mois"
                      : teaching.choice?.formation_duration + " Mois"}
                </td>

                <td>{teaching.choice?.session_nb}</td>
                <td>{teaching.choice?.session_duration}H</td>
                <td>{teaching.choice?.price}Dh</td>
                <td className="text-center" style={{ alignItems: "center" }}>
                  <input
                    type="checkbox"
                    checked={teaching.active}
                    onChange={() => handleCheckboxChange(teaching)}
                    style={{ width: "20px", marginTop: "8px" }}
                  />
                </td>
                <td className="text-center">
                  <FaTrash
                    className="m-2"
                    style={{ color: "red" }}
                    onClick={() => {
                      setCurrentTeachingStudent(teaching);
                      setShowTeachingStudentDeleteModal(true);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Pagination Controls */}
      <Row className="justify-content-center mt-3">
        <Col xs="auto">
          <Pagination>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Col>
      </Row>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showTeachingStudentDeleteModal}
        onHide={handleCloseDeleteModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Êtes-vous sûr de vouloir supprimer l'étudiant à enseigner "
            {currentTeachingStudent?.student?.nom}
            {currentTeachingStudent?.student?.prenom}"?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowTeachingStudentDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmTeachingStudentDelete}>
            supprimer
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default IndividualTable;
