import React from "react";
import styled from "styled-components";
// Components
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
        </div>
      </div>
      <div className="lightBgs" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h2 className="font40 extraBold">Ce qu'ils disent ?</h2>
            <p className="font13">
            "C'est ce que nos utilisateurs disent de nous et nous sommes heureux de partager votre expérience,
              <br />
              qu'elle soit positive ou négative, afin d'améliorer notre application pour le mieux."
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;