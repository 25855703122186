import React from "react";
import {  Button } from "react-bootstrap";
import Select from "react-select";

const GroupSelector = ({
  selectedFormation,
  groups,
  selectedGroupId,
  setSelectedGroupId,
  handleShowGroupDetails,
}) => {

  const capitalizeFirstLetter = (str) => {
    if (!str) return str; 
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };


  return (
    <>
      {selectedFormation && (
        <>
          <Select
            options={groups?.map((g) => ({
              value: g.id,
              label: capitalizeFirstLetter(g.name),
              level :g.level?.name
            }))}
            onChange={(selectedOption) =>
              setSelectedGroupId(selectedOption.value)
            } 
            getOptionLabel={(option) => (
              <div>
                <span>
                  {capitalizeFirstLetter(option.label)}
                </span>
                {option.level && (
                  <span
                    style={{
                      fontSize: "0.8em",
                      color: "gray",
                      marginLeft: "5px",
                      fontStyle: "italic",
                    }}
                  >
                    {option?.level?.toUpperCase()}
                  </span>
                )}
              </div>
            )}
            placeholder="Nom de groupe"
            className="select-group"
          />
          
          <Button
            variant="info"
            onClick={handleShowGroupDetails}
            className="ms-2"
            size="sm"
            style={{ border: "none" }}
          >
            Détails
          </Button>
        </>
      )}
    </>
  );
};

export default GroupSelector;
