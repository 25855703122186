import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGroups,
  deleteGroup,
  updateGroup,
} from "../../Redux/groupe/Group";
import { fetchLevelsByFormation } from "../../Redux/levels/levelsSlice";
import { fetchProfessors } from "../../Redux/Profs/professorsSlice";
import { determineChoice } from "../../Redux/choix/teachingChoiceSlice";
import { fetchNextThreeSessions } from "../../Redux/groupSessions/groupSessions";
import { fetchStudents } from "../../Redux/Etudent/studentsSlice";
import {
  Button,
  Table,
  Modal,
  Form,
  Row,
  Col,
  Nav,
  Spinner,
} from "react-bootstrap";
import { FaEdit, FaPlus, FaTrash, FaUsers } from "react-icons/fa";
import { addStudent } from "../../Redux/groupStudents/GroupStudentsSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./groupe.css";
import Select from "react-select";
import { useDebounce } from "use-debounce";
import { setCurrentPage } from "../../Redux/teachingStudents/teachingStudentSlice";
import IndividualTable from "./IndividualTable";

export const Groupe = () => {
  const { formationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState();
  const groups = useSelector((state) => state.groups.groups);

  const levels = useSelector((state) => state.levels.levels);
  const status = useSelector((state) => state.groups.status);
  const error = useSelector((state) => state.groups.error);
  const [professorSearchTerm, setProfessorSearchTerm] = useState("");
  const [selectedProfessor, setSelectedProfessor] = useState("");
  const [debouncedSearchTerm] = useDebounce(professorSearchTerm, 600);
  const [studentSearchTerm, setStudentSearchTerm] = useState("");
  const [debouncedStudentSearchTerm] = useDebounce(studentSearchTerm, 600);
  const students = useSelector((state) => state.students.students);
  const { selectedYear } = useSelector((state) => state.schoolYears);
  const professors = useSelector((state) => state.professors.professors);
  const determines = useSelector(
    (state) => state.teachingChoices.teachingChoices
  );
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [firstSession, setFirstSession] = useState(null);
  const [formData, setFormData] = useState({
    formation_id: "",
    name: "",
    level_id: "",
    teacher_id: "",
    choice_id: "",
  });
  const [sessions, setSessions] = useState([]);
  const [activeTab, setActiveTab] = useState("groups");
  const [isLoading, setIsLoading] = useState(false);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  // affect student a groupe
  const handleAddStudentSubmit = async (e) => {
    e.preventDefault();

    if (!selectedStudent) {
      toast.error("Veuillez sélectionner un étudiant.");
      return;
    }

    const studentData = {
      group_id: currentGroup,
      student_id: selectedStudent,
      ...(firstSession && { first_session: firstSession.id }),
    };

    try {
      setIsLoading(true);
      await dispatch(addStudent(studentData)).unwrap();
      toast.success("Étudiant ajouté avec succès!");
      handleCloseAddStudentModal();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (error) => {
    const status = error?.status || error?.response?.status;

    switch (status) {
      case 403:
        navigate("/Forbidden");
        break;
      case 404:
        navigate("/not-found");
        break;
      case 401:
        navigate("/Unauthorized");
        break;
      case 500:
        navigate("/internal-error");
        break;
      case 402:
        toast.error(
          "Abonnement terminé. Veuillez renouveler votre abonnement."
        );
        break;
      case 400:
        toast.error("Déjà étudiant dans le groupe .");
        break;
      default:
        toast.error(error.message || "An unexpected error occurred.");
        break;
    }
  };

  // affiche 3 seance
  const handleAddStudentClick = async (groupId) => {
    const fetchedSessions = await dispatch(fetchNextThreeSessions(groupId));
    if (Array.isArray(fetchedSessions.payload)) {
      setSessions(fetchedSessions.payload);
    } else {
      setSessions([]);
    }
    setShowAddStudentModal(true);
  };

  // useEffect de fetch student
  useEffect(() => {
    if (selectedYear && showAddStudentModal) {
      dispatch(
        fetchStudents({
          yearId: selectedYear,
          searchTerm: debouncedStudentSearchTerm,
        })
      );
    }
  }, [debouncedStudentSearchTerm, dispatch, selectedYear, showAddStudentModal]);

  useEffect(() => {
    if (showGroupModal) {
      dispatch(fetchProfessors({ searchTerm: debouncedSearchTerm }));
    }
  }, [debouncedSearchTerm, dispatch, showGroupModal]);

  useEffect(() => {
    if (activeTab === "groups") {
      const fetchGroupsAndLevels = async () => {
        try {
          if (formationId) {
            await dispatch(
              fetchGroups({ formation: formationId, year_id: selectedYear })
            ).unwrap();
          }
        } catch (err) {}
      };
      fetchGroupsAndLevels();
    }
  }, [formationId, dispatch, selectedYear, activeTab]);

  useEffect(() => {
    if (activeTab === "groups") {
      const fetchGroupsAndLevels = async () => {
        try {
          if (formationId) {
            if (showGroupModal) {
              await dispatch(fetchLevelsByFormation(formationId)).unwrap();
            }
          }
        } catch (err) {}
      };
      fetchGroupsAndLevels();
    }
  }, [formationId, dispatch, activeTab, showGroupModal]);

  useEffect(() => {
    const determineChoiceData = async () => {
      if (showGroupModal) {
        try {
          if (formationId) {
            await dispatch(
              determineChoice({ formationId, levelId: formData.level_id })
            ).unwrap();
          }
        } catch (err) {}
      }
    };

    determineChoiceData();
  }, [formationId, formData.level_id, dispatch, showGroupModal]);

  const handleShowGroupModal = (group) => {
    setCurrentGroup(group);
    setFormData({
      formation_id: formationId,
      name: group.name,
      level_id: group.level_id,
      teacher_id: group.teacher_id,
      choice_id: group.choice_id,
      year_id: selectedYear,
    });
    setActiveId(group.choice_id);
    setSelectedProfessor(group.teacher_id);
    setShowGroupModal(true);
  };

  const handleCloseGroupModal = () => {
    setShowGroupModal(false);
    setSelectedProfessor("");
  };
  const handleCloseAddStudentModal = () => {
    setShowAddStudentModal(false);
    setActiveIndex(null);
    setFirstSession(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChoiceClick = (choice) => {
    setActiveId(choice.id);
    setFormData((prevData) => ({ ...prevData, choice_id: choice.id }));
  };

  const handleDelete = (id) => {
    setCurrentGroup(groups.find((group) => group.id === id));
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      if (currentGroup) {
        await dispatch(deleteGroup(currentGroup.id)).unwrap();
        toast.success("Groupe supprimé avec succès!");
        dispatch(
          fetchGroups({ formation: formationId, year_id: selectedYear })
        );
      }
    } catch (error) {
      const status = error?.status || error?.response?.status;

      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    }
    setShowDeleteModal(false);
  };

  const validateGroupData = (formData, selectedYear) => {
    const errors = [];

    if (!formData.formation_id) {
      errors.push("La formation est requise.");
    }
    if (!formData.choice_id) {
      errors.push("Le choix est requise.");
    }
    if (!selectedYear) {
      errors.push("L'année sélectionnée est requise.");
    }
    if (!formData.name) {
      errors.push("Le nom du groupe est requise.");
    }
    return errors;
  };

  const handleSubmitGroup = async (e) => {
    e.preventDefault();

    const validationErrors = validateGroupData(formData, selectedYear);
    if (validationErrors.length > 0) {
      validationErrors.forEach((error) => toast.error(error));
      return;
    }

    const groupData = {
      formation_id: formData.formation_id,
      name: formData.name,
      ...(formData.level_id && { level_id: formData.level_id }),
      ...(selectedProfessor && { teacher_id: selectedProfessor }),

      choice_id: formData.choice_id,
    };

    try {
      await dispatch(updateGroup({ id: currentGroup.id, groupData })).unwrap();
      toast.success("Groupe mis à jour avec succès!");
      dispatch(fetchGroups({ formation: formationId, year_id: selectedYear }));
    } catch (error) {
      const status = error?.status || error?.response?.status;

      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    } finally {
      handleCloseGroupModal();
    }
  };

  if (status === "loading") {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          minHeight: "80vh",
          textAlign: "center",
        }}
      >
        Loading...
      </div>
    );
  }

  if (status === "failed") {
    let errorMessage;

    switch (error.status) {
      case 404:
        errorMessage = "Erreur : Page non trouvée ! 'Ajoute  Année scolaire'";
        break;
      case 400:
        errorMessage = "Erreur : Année scolaire manquante.";
        break;
      case 500:
        errorMessage = "Erreur : Problème serveur.";
        break;
      default:
        errorMessage = "Erreur inconnue.";
        break;
    }

    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          minHeight: "80vh",
          textAlign: "center",
        }}
      >
        <div>{errorMessage}</div>
      </div>
    );
  }

  const handleCheckboxChange = async (groupId) => {
    const group = groups.find((g) => g.id === groupId);
    try {
      if (group) {
        const updatedActiveState = !group.active;
        const updatedGroup = { active: updatedActiveState };

        await dispatch(
          updateGroup({ id: groupId, groupData: updatedGroup })
        ).unwrap();

        dispatch(
          fetchGroups({ formation: formationId, year_id: selectedYear })
        );

        toast.success(
          `Groupe "${group.name}" est maintenant ${
            updatedActiveState ? "actif" : "inactif"
          }.`
        );
      }
    } catch (error) {
      const status = error?.status || error?.response?.status;

      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    }
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  return (
    <div>
      <Row className="align-items-center mb-2">
        <Col xs={12} md={6} className="text-md-start text-center">
          <h4>Tableau des groupes et des étudiants</h4>
        </Col>
      </Row>
      <Nav variant="tabs" activeKey={activeTab} onSelect={handleTabSelect}>
        <Nav.Item>
          <Nav.Link eventKey="groups">Groupes</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="indvidual">Étudiants</Nav.Link>
        </Nav.Item>
      </Nav>

      {activeTab === "groups" && (
        <div className="table-responsive-etud">
          <Table hover size="lg" responsive className="mt-3" bordered>
            <thead>
              <tr className="text-center">
                <th>Groupe</th>
                <th>Niveau</th>
                <th>Prof</th>
                <th>Choix Méthode</th>
                <th>Durée Formation</th>
                <th>Nbr Séance par Semaine</th>
                <th>Durée Séance</th>
                <th>Prix</th>
                <th>Actif</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group) => (
                <tr key={group.id} className="text-center">
                  <td>{capitalizeFirstLetter(group.name)}</td>
                  <td>{group.level?.name?.toUpperCase() || "Non existant"}</td>
                  <td>
                    {group.teacher?.nom && group.teacher?.prenom
                      ? `${capitalizeFirstLetter(
                          group.teacher.nom
                        )} ${capitalizeFirstLetter(group.teacher.prenom)}`
                      : "Non existant"}
                  </td>
                  <td>{group.choice?.teaching_method}</td>
                  <td>
                  {group.choice?.formation_duration === 0
                      ? "Par mois"
                      : group.choice?.formation_duration + " Mois"}
                  </td>
                  <td>{group.choice?.session_nb} </td>
                  <td>{group.choice?.session_duration}H</td>
                  <td>{group.choice?.price}Dh</td>
                  <td className="text-center" style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      checked={group.active}
                      onChange={() => handleCheckboxChange(group.id)}
                      style={{ width: "20px", marginTop: "8px" }}
                    />
                  </td>

                  <td className="text-center">
                    <FaTrash
                      className="m-2 "
                      style={{ color: "red" }}
                      onClick={() => handleDelete(group.id)}
                    />
                    <FaEdit
                      className="m-2 "
                      style={{ color: "green" }}
                      onClick={() => handleShowGroupModal(group)}
                    />
                    <FaPlus
                      className="m-2 "
                      style={{ color: "blue" }}
                      onClick={() => {
                        setShowAddStudentModal(true);
                        handleAddStudentClick(group.id);
                        setCurrentGroup(group.id);
                      }}
                    />
                    <Link to={`/dashboard/groupe-etudent/${group.id}`}>
                      <FaUsers
                        className="m-2 "
                        style={{ color: "blueviolet" }}
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {activeTab === "indvidual" && (
        <IndividualTable
          selectedYear={selectedYear}
          handlePageChange={handlePageChange}
          activeTab={activeTab}
        />
      )}

      {/* Modal for Editing Group */}
      <Modal show={showGroupModal} onHide={handleCloseGroupModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier Group</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitGroup}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Nom de groupe:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formLevelSelect">
                  <Form.Label>sélectionner Niveau</Form.Label>
                  <Form.Select
                    name="level_id"
                    value={formData.level_id}
                    onChange={handleChange}
                  >
                    <option value="">sélectionner Nivaeu</option>
                    {levels.map((level) => (
                      <option key={level.id} value={level.id}>
                        {level?.name?.toUpperCase()}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formProfessorSelect">
                  <Form.Label>sélectionner Professor</Form.Label>
                  <Select
                    name="teacher_id"
                    options={professors.map((p) => ({
                      value: p.id,
                      label: `${p.nom} ${p.prenom}`,
                    }))}
                    value={
                      selectedProfessor
                        ? {
                            value: selectedProfessor,
                            label: `${
                              professors.find((p) => p.id === selectedProfessor)
                                ?.nom
                            } ${
                              professors.find((p) => p.id === selectedProfessor)
                                ?.prenom
                            }`,
                          }
                        : null
                    }
                    placeholder="Select prof"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setSelectedProfessor(selectedOption.value);
                      } else {
                        setSelectedProfessor(null);
                      }
                    }}
                    onInputChange={(inputValue) =>
                      setProfessorSearchTerm(inputValue)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="tickets">
                  <h5>Choix :</h5>
                  <ul>
                    {determines
                      .filter((choice) => choice.teaching_method === "Group")
                      .map((determine) => (
                        <li
                          key={determine.id}
                          className={`ticket ${
                            activeId === determine.id ? "active" : ""
                          }`}
                          onClick={() => handleChoiceClick(determine)}
                          style={{ cursor: "pointer" }}
                        >
                          <p className="price">{determine.price} Dh</p>
                          <div className="numbers">
                            <p>{determine.session_duration} h / Séance</p>
                            <p>{determine.session_nb} Séance/Semaine</p>
                            <p>{determine.formation_duration} par mois</p>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className="mr-auto" type="submit">
              Modifier Group
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Modal for Delete Confirmation */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Êtes-vous sûr de vouloir supprimer le groupe "{currentGroup?.name}"?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Adding Student */}
      <Modal show={showAddStudentModal} onHide={handleCloseAddStudentModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un étudiant au groupe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Select
                options={students.map((student) => ({
                  value: student.id,
                  label: `${capitalizeFirstLetter(
                    student.nom
                  )} ${capitalizeFirstLetter(student.prenom)}`,
                }))}
                onInputChange={(inputValue) => setStudentSearchTerm(inputValue)}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setSelectedStudent(selectedOption.value);
                  } else {
                    setSelectedStudent(null);
                  }
                }}
                placeholder="sélectionner a Etudent..."
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>premier 3 seance :</Form.Label>
              <div className="groupSeance mt-4">
                {sessions.map((session, index) => (
                  <div
                    key={index}
                    className={`seance ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveIndex(index);
                      setFirstSession(session);
                    }}
                  >
                    <div className="date">
                      {new Date(session.start).toLocaleDateString()}
                    </div>
                    <div className="day">
                      {new Date(session.start).toLocaleString("fr-FR", {
                        weekday: "long",
                      })}
                    </div>
                    <div className="time">
                      {new Date(session.start).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </Form.Group>
            <div className="text-end mt-5">
              <Button
                variant="primary"
                size="sm"
                style={{ border: "none" }}
                onClick={handleAddStudentSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" />
                    {" Enregistrement..."}
                  </>
                ) : (
                  "Ajouter Etudent"
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
