import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

export const determineChoice = createAsyncThunk(
  "teachingChoices/determineChoice",
  async ({ formationId, levelId }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get("/determine-choices", {
        params: { formation: formationId, level: levelId },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const fetchTeachingChoices = createAsyncThunk(
  "teachingChoices/fetchTeachingChoices",
  async (
    { page = 1, pageSize = 10, teaching_method, formation },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.get("/teaching-choices", {
        params: { page, pageSize, teaching_method, formation },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const addTeachingChoice = createAsyncThunk(
  "teachingChoices/addTeachingChoice",
  async (teachingChoiceData, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        "/teaching-choices",
        teachingChoiceData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const updateTeachingChoice = createAsyncThunk(
  "teachingChoices/updateTeachingChoice",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      await axiosPrivate.put(`/teaching-choices/${id}`, updatedData);
      return { id, ...updatedData };
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const deleteTeachingChoice = createAsyncThunk(
  "teachingChoices/deleteTeachingChoice",
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/teaching-choices/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

const teachingChoiceSlice = createSlice({
  name: "teachingChoices",
  initialState: {
    teachingChoices: [],
    status: "idle",
    error: null,
    totalTechingChoices: 0,
    currentPage: 1,
    pageSize: 10,
  },
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    resetTeachingChoices(state) {
      state.teachingChoices = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeachingChoices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTeachingChoices.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teachingChoices = action.payload.teachingChoices;
        state.totalTechingChoices = action.payload.totalTechingChoices;
      })
      .addCase(fetchTeachingChoices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addTeachingChoice.fulfilled, (state, action) => {
        state.teachingChoices.push(action.payload);
      })
      .addCase(updateTeachingChoice.fulfilled, (state, action) => {
        const { id, ...updatedData } = action.payload;
        const index = state.teachingChoices.findIndex(
          (choice) => choice.id === id
        );
        if (index !== -1) {
          state.teachingChoices[index] = {
            ...state.teachingChoices[index],
            ...updatedData,
          };
        }
      })
      .addCase(deleteTeachingChoice.fulfilled, (state, action) => {
        const id = action.payload;
        state.teachingChoices = state.teachingChoices.filter(
          (choice) => choice.id !== id
        );
      })
      .addCase(deleteTeachingChoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(determineChoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(determineChoice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teachingChoices = action.payload;
      })
      .addCase(determineChoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setCurrentPage  , resetTeachingChoices} = teachingChoiceSlice.actions;

export default teachingChoiceSlice.reducer;
