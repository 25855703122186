import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from "../../api/axios";

const initialState = {
    groups: [],
    status: 'idle', 
    error: null,
};

export const fetchGroups = createAsyncThunk(
    'groups/fetchGroups', 
    async ({ formation, year_id }, { rejectWithValue }) => {
        try {
            const response = await axiosPrivate.get(`/groups/${formation}/year/${year_id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue({
              message: error.message,
              status: error.response?.status,
            });
          }
    }
);

export const fetchGroupById = createAsyncThunk(
    'groups/fetchGroupById', 
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosPrivate.get(`/group/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue({
              message: error.message,
              status: error.response?.status,
            });
          }
    }
);

export const addGroup = createAsyncThunk(
    'groups/addGroup', 
    async (groupData, { rejectWithValue }) => {
        try {
            const response = await axiosPrivate.post('/groups', groupData);
            return response.data; 
        } catch (error) {
            return rejectWithValue({
              message: error.message,
              status: error.response?.status,
            });
          }
    }
);

export const updateGroup = createAsyncThunk(
    'groups/updateGroup', 
    async ({ id, groupData }, { rejectWithValue }) => {
        try {
            const response = await axiosPrivate.put(`/groups/${id}`, groupData);
            return { id, groupData: response.data };
        }catch (error) {
            return rejectWithValue({
              message: error.message,
              status: error.response?.status,
            });
          }
    }
);

export const deleteGroup = createAsyncThunk(
    'groups/deleteGroup', 
    async (id, { rejectWithValue }) => {
        try {
            await axiosPrivate.delete(`/groups/${id}`);
            return id; 
        } catch (error) {
            return rejectWithValue({
                status: error.response?.status || 500,
                // message: error.response?.data?.message || error.message,
            });
        }
    }
);

const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
      resetError(state) {
        state.error = null; 
    },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGroups.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGroups.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.groups = action.payload; 
            })
            .addCase(fetchGroups.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload; 
            })
            .addCase(fetchGroupById.fulfilled, (state, action) => {
              const updatedGroup = action.payload;
              const groupExists = state.groups.some(group => group.id === updatedGroup.id);
          
              if (!groupExists) {
                  state.groups.push(updatedGroup);
              } else {
                  state.groups = state.groups.map(group =>
                      group.id === updatedGroup.id ? updatedGroup : group
                  );
              }
          })          
            .addCase(fetchGroupById.rejected, (state, action) => {
                state.error = action.payload; 
            })
            .addCase(addGroup.fulfilled, (state, action) => {
                state.groups.push(action.payload);
            })
            .addCase(addGroup.rejected, (state, action) => {
              state.error = action.payload ;
            })
            .addCase(updateGroup.fulfilled, (state, action) => {
              const { id, groupData } = action.payload;
              state.groups = state.groups.map(group =>
                  group.id === id ? { ...group, ...groupData } : group
              );
          })          
            .addCase(updateGroup.rejected, (state, action) => {
                state.error = action.payload; 
            })
            .addCase(deleteGroup.fulfilled, (state, action) => {
                const id = action.payload;
                state.groups = state.groups.filter(group => group.id !== id); 
            })
            .addCase(deleteGroup.rejected, (state, action) => {
                state.error = action.payload;
            });
    },
});

export const { resetError } = groupsSlice.actions;

export default groupsSlice.reducer;
