import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { verifyEmail } from '../../Redux/Auth/authSlice';
import TopNavbar from '../../pages/Site/components/Nav/TopNavbar';

const VerifyEmailPage = () => {
    const { token } = useParams();  // Récupère le token depuis l'URL
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    // Récupère le status de la vérification de l'email et les erreurs depuis Redux
    const { verifyEmailStatus, verifyEmailError } = useSelector((state) => state.auth);

    useEffect(() => {
        if (token) {
            dispatch(verifyEmail(token));  // Déclenche la vérification de l'email avec le token
        }
    }, [dispatch, token]);

    const handleRedirectToHome = () => {
        navigate('/');  // Redirige l'utilisateur vers la page d'accueil
    };

    let content;
    let bgColor = '';

    switch (verifyEmailStatus) {
        case 'loading':
            content = <p>Vérification de votre email...</p>;
            bgColor = 'yellow';
            break;
        case 'succeeded':
            content = (
                <>
                    <p>Merci de vérifier votre email <span role="img" aria-label="smiley">😊</span></p>
                    <p>Votre email a été vérifié avec succès!</p>
                </>
            );
            bgColor = 'green';
            break;
        case 'failed':
            content = <p>Erreur lors de la vérification de l'email : {verifyEmailError}</p>;
            bgColor = 'red';
            break;
        default:
            content = <p>Chargement...</p>;
            bgColor = 'white';
            break;
    }

    return (
        <div 
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: 'rgb(38 12 243 / 18%)', 
            }}
        >
                  <TopNavbar />
            <div 
                style={{
                    width: '500px', // Largeur du conteneur
                    padding: '20px',
                    borderRadius: '8px',
                    backgroundColor: bgColor,
                    textAlign: 'center',
                    color: 'white',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Ombre pour plus d'esthétique
                    margin: '20px auto', // Pour centrer horizontalement
                }}
            >
                {content}
                
                {(verifyEmailStatus === 'succeeded' || verifyEmailStatus === 'failed') && (
                    <button
                        onClick={handleRedirectToHome}
                        style={{
                            marginTop: '20px',
                            padding: '10px 20px',
                            backgroundColor: 'blue',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        Retour à l'accueil
                    </button>
                )}
            </div>
        </div>
    );
};

export default VerifyEmailPage;
