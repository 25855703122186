import React, { Suspense, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Modal as BootstrapModal,
  Form,
  Col,
  Dropdown,
  Button,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
  MDBIcon,
} from "mdb-react-ui-kit";

import {
  clearStore,
  fetchSchoolYears,
  setSelectedYear,
} from "../../Redux/Year/schoolYearsSlice";
import { updateProfile } from "../../Redux/Profile/profileSlice";
import "./Dashboard.css";
import "./profile.css";
import "./menu.css";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "../../Redux/Auth/authSlice";
import { AjouteYears } from "../parametres/AjouteYears";
import { Compte } from "../parametres/Compte";
import logo from "../../assets/Logo.png";
import directeur from "../../assets/directeur.png";
import moment from "moment";
import { Helmet } from "react-helmet";
import { clearStoreAccount } from "../../Redux/Compte/accountsSlice";
import { clearStudents } from "../../Redux/Etudent/studentsSlice";
import { clearDashboard } from "../../Redux/Dashboard/dashboardSlice";
import { resetPayments } from "../../Redux/payment/paymentSlice";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector((e) => e);
  const { selectedYear } = useSelector((state) => state.schoolYears);
  const schoolYears = useSelector((state) => state.schoolYears.schoolYears);
  const [isSidebarClosed, setSidebarClosed] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);

  const [currentContent, setCurrentContent] = useState(null);

  const handleNewYearClick = () => {
    setCurrentContent(<AjouteYears />);
  };

  const handleAccountManagementClick = () => {
    setCurrentContent(<Compte />);
  };

  // modal de profile---------------------------------------------
  const [showProfile, setShowProfile] = useState(false);
  const toggleProfile = () => setShowProfile(!showProfile);
  const { profileData, loading } = useSelector((state) => state.profile);

  const [isEditing, setIsEditing] = useState(false);
  const [updatedNom, setUpdatedNom] = useState(auth.user.profile?.nom || "");
  const [updatedPrenom, setUpdatedPrenom] = useState(
    auth.user.profile?.prenom || ""
  );
  const [updatedPhone, setUpdatedPhone] = useState(auth.user.profile?.phone);

  const [originalNom, setOriginalNom] = useState("");
  const [originalPrenom, setOriginalPrenom] = useState("");
  const [originalPhone, setOriginalPhone] = useState("");

  const [showEmptyModal, setShowEmptyModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  // DATE FINN DE SUPSCRIPTION
  const [remainingTime, setRemainingTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  // DATE FINN DE SUPSCRIPTION

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(fetchSchoolYears()).unwrap();
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && schoolYears.length === 0) {
      setShowEmptyModal(true);
    }
  }, [isLoading, schoolYears.length]);

  useEffect(() => {
    if (auth) {
      setOriginalNom(auth.user.profile?.nom);
      setOriginalPrenom(auth.user.profile?.prenom);
      setOriginalPhone(auth.user.profile?.phone);
      setUpdatedNom(originalNom);
      setUpdatedPrenom(originalPrenom);
      setUpdatedPhone(originalPhone);
    }
  }, [auth, originalNom, originalPrenom, originalPhone]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    const profileData = {
      id: auth.user.profile.id,
      nom: updatedNom,
      prenom: updatedPrenom,
      phone: updatedPhone,
    };

    try {
      const updatedProfile = await dispatch(
        updateProfile(profileData)
      ).unwrap();
      toast.success("Profil mis à jour avec succès !");
      setIsEditing(false);
      setUpdatedNom(updatedProfile.nom);
      setUpdatedPrenom(updatedProfile.prenom);
      setUpdatedPhone(updatedProfile.phone);
    } catch (error) {
      const status = error?.status || error?.response?.status;
      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message);
          break;
      }
    } finally {
      setUpdatedNom(originalNom);
      setUpdatedPrenom(originalPrenom);
      setUpdatedPhone(originalPhone);
    }
  };

  const handleCancelClick = () => {
    setUpdatedNom(originalNom);
    setUpdatedPrenom(originalPrenom);
    setUpdatedPhone(originalPhone);
    setIsEditing(false);
  };
  // end modal de profile----------------------------------------------------------------

  const handleShow = () => {
    setShowModal(true);
    setCurrentContent(
      <AjouteYears handleClose={handleClose} handleShow={handleShow} />
    );
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSidebarClosed(true);
      } else if (window.innerWidth > 768) {
        setSidebarClosed(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSidebarToggle = () => {
    setSidebarClosed((prev) => !prev);
  };



  const isActiveRoute = (route) => {
    return location.pathname === route;
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/login");

    // clean store
    dispatch(clearStore());
    dispatch(clearStoreAccount());
    dispatch(clearStudents());
    dispatch(clearDashboard());
    dispatch(resetPayments());
  };

  const handleYearChange = (event) => {
    dispatch(setSelectedYear(event.target.value));
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  // use effect de calculer le temps
  useEffect(() => {
    if (auth.user.subscriptionEnd) {
      const interval = setInterval(() => {
        const now = moment();
        const endDate = moment(auth.user.subscriptionEnd);
        const duration = moment.duration(endDate.diff(now));

        const years = Math.floor(duration.asYears());
        const months = Math.floor(duration.asMonths()) - years * 12; 
        const days = duration.days(); 
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        setRemainingTime({ years, months, days, hours, minutes, seconds });

        if (duration.asMilliseconds() <= 0) {
          setIsSubscriptionExpired(true);
          clearInterval(interval);
        }
      }, 1000); 

      return () => clearInterval(interval);
    }
  }, [auth.user.subscriptionEnd]);

  return (
    <>
      <Helmet>
        <title>Tableau de Bord | Gycole - Gestion des Centres Éducatifs</title>
      </Helmet>

      <div className={`sidebar ${isSidebarClosed ? "close" : ""}`}>
        <Link to="#" className={`${!isSidebarClosed ? "logo" : "logo-sm"}`}>
          <img src={logo} alt="" width="100px" />
        </Link>
        <ul className="side-menu">
          <li className={isActiveRoute("/dashboard/home") ? "actives" : ""}>
            <Link to="/dashboard/home" className="link">
              <i className="bx bxs-dashboard"></i>Tableau de bord
            </Link>
          </li>
          <li className={isActiveRoute("/dashboard/calendar") ? "actives" : ""}>
            <Link to="/dashboard/calendar" className="link">
              <i className="bx bx-calendar"></i> Calendrier
            </Link>
          </li>
          <li className={isActiveRoute("/dashboard/Etudiant") ? "actives" : ""}>
            <Link to="/dashboard/Etudiant" className="link">
              <i className="bx bxs-user"></i>Etudiant
            </Link>
          </li>
          <li className={isActiveRoute("/dashboard/Profs") ? "actives" : ""}>
            <Link to="/dashboard/Profs" className="link">
              <i className="bx bxs-group"></i> Professeur
            </Link>
          </li>
          <li
            className={
              isActiveRoute("/dashboard/formation-groupe") ? "actives" : ""
            }
          >
            <Link to="/dashboard/formation-groupe" className="link">
              <i className="bx bx-book"></i>Formation
            </Link>
          </li>
          <li className={isActiveRoute("/dashboard/choix") ? "actives" : ""}>
            <Link to="/dashboard/choix" className="link">
              <i class="bx bx-list-check"></i>
              Choix
            </Link>
          </li>
          <li
            className={
              isActiveRoute("/dashboard/Paiement-etudent") ? "actives" : ""
            }
          >
            <Link to="/dashboard/Paiement-etudent" className="link">
              <i className="bx bx-credit-card"></i> Paiement
            </Link>
          </li>
          {auth?.user?.role === "Director" && (
            <li
              className={isActiveRoute("/dashboard/archive") ? "actives" : ""}
            >
              <Link to="/dashboard/archive" className="link">
                <i className="bx bx-archive"></i> Archive
              </Link>
            </li>
          )}
        </ul>
        <ul className="side-menu">
          <li>
            <Link
              to="#"
              className="logout link"
              onClick={(e) => handleLogout(e)}
            >
              <i className="bx bx-log-out-circle"></i>
              Se Déconnecter
            </Link>
          </li>
        </ul>
      </div>
      <div className="content">
        <nav>
          <i className="bx bx-menu" onClick={handleSidebarToggle}></i>
          <form>
            <p className="ref-id"> ID: {auth.user?.ref}</p>
          </form>

          {schoolYears.length > 0 && (
            <Col className="text-md-end text-center ">
              <Form.Select
                style={{ width: "130px", display: "inline-block" }}
                size="sm"
                value={selectedYear}
                onChange={handleYearChange}
              >
                {schoolYears.map((year) => (
                  <option key={year.id} value={year.id}>
                    {year.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          )}
          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
              style={{ border: "none" }}
              className="dropdown"
            ></Dropdown.Toggle>

            <Dropdown.Menu>
              {auth?.user?.role === "Director" && (
                <Dropdown.Item
                  as="button"
                  onClick={handleShow}
                  className="drop"
                >
                  <i className="bx bxs-cog"></i>Paramétres
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={toggleProfile} className="drop">
                <i className="bx bxs-user"></i> Profile
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </nav>
        <main>
          <Outlet />
        </main>
      </div>

      {/* Modal for Settings */}
      <BootstrapModal
        show={showModal}
        onHide={handleClose}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title className="text-center">
            Paramètre
          </BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body className="custom-sidebar" style={{ padding: 0 }}>
          <div className="all-para">
            <main className="sidebar-Modal">
              <ul>
                <li>
                  <Button onClick={handleNewYearClick}>
                    Ajoute Nouvelle Année
                  </Button>
                </li>
                <li>
                  <Button onClick={handleAccountManagementClick}>
                    Gestion Des Comptes
                  </Button>
                </li>
              </ul>
            </main>

            <main className="main-modal">
              <Suspense fallback={<div>Loading...</div>}>
                {currentContent}
              </Suspense>
            </main>
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>

      {/* modal de profile pro  */}
      {auth && showProfile && (
        <div className="custom-modal">
          <button className="close-btn" onClick={toggleProfile}>
            X
          </button>
          <MDBContainer className="py-5 modal-profile">
            <MDBRow className="justify-content-center">
              <MDBCol lg="8" className="">
                <MDBCard className="mb-3" style={{ borderRadius: ".5rem" }}>
                  <MDBRow className="g-0">
                    <MDBCol
                      md="4"
                      className="gradient-custom text-center text-white"
                      style={{
                        borderTopLeftRadius: ".5rem",
                        borderBottomLeftRadius: ".5rem",
                      }}
                    >
                      <MDBCardImage
                        src={directeur}
                        alt="Avatar"
                        className="my-5"
                        style={{ width: "100px", background: "red" }}
                        fluid
                      />
                      <MDBTypography tag="h5" className="nom">
                        {isEditing ? (
                          <>
                            <input
                              type="text"
                              value={updatedNom}
                              onChange={(e) => setUpdatedNom(e.target.value)}
                              placeholder="Nom"
                              style={{
                                height: "30px",
                                marginBottom: "5px",
                                marginLeft: "5px",
                                width: "220px",
                              }}
                            />
                            <input
                              type="text"
                              value={updatedPrenom}
                              onChange={(e) => setUpdatedPrenom(e.target.value)}
                              placeholder="Prénom"
                              style={{
                                height: "30px",
                                marginBottom: "5px",
                                marginLeft: "5px",
                                width: "220px",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            {profileData
                              ? `${capitalizeFirstLetter(
                                  profileData.nom
                                )} ${capitalizeFirstLetter(profileData.prenom)}`
                              : `${capitalizeFirstLetter(
                                  auth.user.profile?.nom
                                )} ${capitalizeFirstLetter(
                                  auth.user.profile?.prenom
                                )}`}
                          </>
                        )}
                      </MDBTypography>
                      <MDBCardText className="prenom">
                        {auth.user.role === "Director"
                          ? "Directeur"
                          : auth.user.role === "Secretary"
                          ? "Secrétaire"
                          : ""}
                      </MDBCardText>
                      <MDBIcon far icon="edit mb-5" />
                    </MDBCol>
                    <MDBCol md="8">
                      <MDBCardBody className="p-4">
                        <MDBTypography tag="h6" className="d-inline">
                          Information
                        </MDBTypography>
                        <FaEdit
                          onClick={handleEditClick}
                          className=" ms-2"
                          style={{ cursor: "pointer" }}
                        />

                        <hr className="mt-0 mb-4" />
                        <MDBRow className="pt-1">
                          <MDBCol size="10" className="mb-3">
                            <MDBTypography tag="h6">Email</MDBTypography>
                            <MDBCardText className="text-muted">
                              {auth.user.email}
                            </MDBCardText>
                          </MDBCol>
                          <MDBCol size="6" className="mb-3">
                            <MDBTypography tag="h6">Téléphone</MDBTypography>
                            {isEditing ? (
                              <input
                                type="text"
                                value={updatedPhone}
                                onChange={(e) =>
                                  setUpdatedPhone(e.target.value)
                                }
                                style={{
                                  height: "30px",
                                  marginTop: "5px",
                                }}
                              />
                            ) : (
                              <MDBCardText className="text-muted">
                                {profileData
                                  ? profileData.phone
                                  : auth.user.profile?.phone}
                              </MDBCardText>
                            )}
                          </MDBCol>
                          <MDBTypography tag="h6">
                            Date de fin d'abonnement :
                          </MDBTypography>
                          {auth.user.subscriptionEnd ? (
                            <p
                              style={{
                                color: isSubscriptionExpired ? "red" : "blue",
                              }}
                            >
                              {isSubscriptionExpired
                                ? "Votre abonnement a expiré"
                                : `${
                                    remainingTime.years > 0
                                      ? `${remainingTime.years}  An${
                                          remainingTime.years > 1 ? "s" : ""
                                        }, `
                                      : ""
                                  }
         ${remainingTime.days} Jour${remainingTime.days > 1 ? "s" : ""}, 
         ${remainingTime.hours} Hours, ${remainingTime.minutes} Minutes, ${
                                    remainingTime.seconds
                                  } Secondes`}
                            </p>
                          ) : (
                            <p>Chargement...</p>
                          )}
                        </MDBRow>
                        {isEditing && (
                          <div className="d-flex justify-content-end ">
                            <button
                              onClick={handleSaveClick}
                              className="btn btn-primary me-2"
                            >
                              {loading ? "Enregistrement..." : "Enregistrement"}
                            </button>
                            <button
                              onClick={handleCancelClick}
                              className="btn btn-secondary"
                            >
                              Annuler
                            </button>
                          </div>
                        )}
                        <div className="d-flex justify-content-start">
                          <a href="#!">
                            <MDBIcon fab icon="facebook me-3" size="lg" />
                          </a>
                          <a href="#!">
                            <MDBIcon fab icon="twitter me-3" size="lg" />
                          </a>
                          <a href="#!">
                            <MDBIcon fab icon="instagram me-3" size="lg" />
                          </a>
                        </div>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      )}

      {/* Empty School Year Modal */}
      <Modal
        show={showEmptyModal}
        onHide={() => setShowEmptyModal(false)}
        className="modal-dark"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Aucune année scolaire trouvée</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong style={{ color: "red" }}>
            Veuillez ajouter une année scolaire.
          </strong>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowEmptyModal(false);
              handleShow();
            }}
          >
            Ajouter une année scolaire
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Dashboard;
