import { configureStore } from '@reduxjs/toolkit';

import studentsReducer from './Etudent/studentsSlice'; 

import archivedStudentsReducer from './Etudent/archivedStudentsSlice'; 

import roomsReducer from './room/roomsSlice';

import categoriesReducer from './categories/categoriesSlice';

import levelsReducer from './levels/levelsSlice';

import professorsReducer from './Profs/professorsSlice';

import archivedTeachersReducer from './Profs/archivedTeachersSlice';

import formationsReducer from './formation/formationSlice'; 

import teachingChoiceReducer from "./choix/teachingChoiceSlice";

import groupsReducer from './groupe/Group';

import groupSessionsReducer from './groupSessions/groupSessions';

import groupStudentsReducer from './groupStudents/GroupStudentsSlice';

import absenceReducer from './groupeSessionAbsentStudent/sessionAbsentSlice';

import paymentReducer from './payment/paymentSlice';

import accountsReducer from './Compte/accountsSlice';

import authReducer from './Auth/authSlice';


import profileReducer from './Profile/profileSlice';

import TeachingStudentReducer from './teachingStudents/teachingStudentSlice';

import TeachingSessionsReducer from './teacheingSession/teachingSessionSlice';

import schoolYearsReducer from './Year/schoolYearsSlice';

import restPasswordReducer from './Auth/resetPasswordSlice';

import dashboardReducer from './Dashboard/dashboardSlice';



const store = configureStore({
  reducer: {
    auth: authReducer,
    students: studentsReducer,
    archivedStudents: archivedStudentsReducer,
    rooms: roomsReducer,
    categories: categoriesReducer,
    levels: levelsReducer,
    professors: professorsReducer,
    archivedTeachers: archivedTeachersReducer,
    formations: formationsReducer, 
    teachingChoices: teachingChoiceReducer,
    groups: groupsReducer,
    groupSessions: groupSessionsReducer,
    groupStudents: groupStudentsReducer,
    absences: absenceReducer,
    payments: paymentReducer,
    accounts: accountsReducer,
    profile: profileReducer,
    teachingSessions : TeachingSessionsReducer,
    teachingStudents : TeachingStudentReducer,
    schoolYears: schoolYearsReducer,
    resetPassword:restPasswordReducer,
    dashboard: dashboardReducer,
  },
  devTools: process.env.REACT_APP_ENV !== 'production',
});

export default store;
