// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './Redux/store';
import InterceptorsProvider from "./provider/InterceptorsProvider";

// site style 
import "./pages/Site/style/flexboxgrid.min.css";
import './pages/Site/style/index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
      <Provider store={store}>
          <BrowserRouter>
        <InterceptorsProvider>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
        </InterceptorsProvider>
          </BrowserRouter>
      </Provider>
  // </React.StrictMode>
);
