import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

const initialState = {
  sessions: [],
  students: [],
  currentSession: null, 
  loading: false,
  error: null,
};


let abortController = new AbortController(); 

export const fetchSessions = createAsyncThunk(
  "groupSessions/fetchSessions",
  async ({ groupId, startDate, endDate }) => {

      if (abortController.signal) {
        abortController.abort();
      }

      abortController = new AbortController();
      const response = await axiosPrivate.get(`/group-sessions/${groupId}`, {
        params: { start_date: startDate, end_date: endDate },
        signal: abortController.signal, 
      });
      return response.data;
    } 
);



export const addSession = createAsyncThunk(
  "groupSessions/addSession",
  async (sessionData, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        "/group-sessions",
        sessionData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const updateSession = createAsyncThunk(
  "groupSessions/updateSession",
  async ({ id, sessionData }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(
        `/group-sessions/${id}`,
        sessionData
      );
      return { id, sessionData: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



export const fetchNextThreeSessions = createAsyncThunk(
  "groupSession/fetchNextThree",
  async (group, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `/group-next-sessions/${group}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



export const deleteSession = createAsyncThunk(
  "groupSessions/deleteSession",
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/group-sessions/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const deleteRepeating = createAsyncThunk(
  "sessions/deleteRepeating",
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/repeating/${id}`);
      return id; 
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const oneSession = createAsyncThunk(
  "groupSessions/oneSession",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/group-session/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const fetchStudentsOfSession = createAsyncThunk(
  "groupSessions/fetchStudentsOfSession",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `/group-session-students/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const groupSessionsSlice = createSlice({
  name: "groupSessions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.sessions = action.payload;
      })
      .addCase(fetchSessions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addSession.fulfilled, (state, action) => {
        state.sessions.push(action.payload);
      })
      .addCase(addSession.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateSession.fulfilled, (state, action) => {
        const { id, sessionData } = action.payload;
        const index = state.sessions.findIndex((session) => session.id === id);
        if (index !== -1) {
          state.sessions[index] = { ...state.sessions[index], ...sessionData };
        }
      })
      .addCase(updateSession.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchNextThreeSessions.fulfilled, (state, action) => {
        state.sessions = action.payload;
      })
      .addCase(fetchNextThreeSessions.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteSession.fulfilled, (state, action) => {
        state.sessions = state.sessions.filter(
          (session) => session.id !== action.payload
        );
      })
      .addCase(deleteSession.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(oneSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(oneSession.fulfilled, (state, action) => {
        state.loading = false;
        state.currentSession = action.payload; 
      })
      .addCase(oneSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchStudentsOfSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStudentsOfSession.fulfilled, (state, action) => {
        state.loading = false;
        state.students = action.payload; 
      })
      .addCase(fetchStudentsOfSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteRepeating.fulfilled, (state, action) => {
        state.sessions = state.sessions.filter(
          (session) => session.repeating_id !== action.payload
        );
      })
      .addCase(deleteRepeating.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default groupSessionsSlice.reducer;
