import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Table,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addSchoolYear,
  fetchSchoolYears,
  deleteSchoolYear,
} from "../../Redux/Year/schoolYearsSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaTrash } from "react-icons/fa";

export const AjouteYears = ({ handleClose, handleShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schoolYears = useSelector((state) => state.schoolYears.schoolYears);
  const [isLoading, SetisLoading] = useState(false);
  const [schoolYear, setSchoolYear] = useState({
    name: "",
    start: "",
    end: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [yearToDelete, setYearToDelete] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSchoolYear((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    // Check if the name is provided
    if (!schoolYear.name) {
      toast.error("Le nom de l'année scolaire est requise.");
      return false;
    }

    // Check if the name matches the format YYYY/YYYY
    const namePattern = /^\d{4}\/\d{4}$/;
    if (!namePattern.test(schoolYear.name)) {
      toast.error("Le nom de l'année scolaire doit être au format AAAA/AAAA");
      return false;
    }

    // Check if the start date is provided
    if (!schoolYear.start) {
      toast.error("La date de début est requise.");
      return false;
    }

    // Check if the end date is provided
    if (!schoolYear.end) {
      toast.error("La Date Fin est requise.");
      return false;
    }

    // Check if the start date is earlier than the end date
    if (new Date(schoolYear.start) >= new Date(schoolYear.end)) {
      toast.error("La date de début doit être antérieure à la date de fin");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      SetisLoading(true);
      await dispatch(addSchoolYear(schoolYear)).unwrap();
      toast.success("Année scolaire ajoutée avec succès !");
      setSchoolYear({ name: "", start: "", end: "" });
      await dispatch(fetchSchoolYears()).unwrap();
      setShowModal(false);
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      SetisLoading(false);
    }
  };

  const handleDelete = (id) => {
    setYearToDelete(id);
    setShowConfirmModal(true);
  };

  const confirmDelete = async () => {
    setShowConfirmModal(false);
    try {
      await dispatch(deleteSchoolYear(yearToDelete)).unwrap();
      toast.success("Année scolaire supprimée avec succès!");
      await dispatch(fetchSchoolYears()).unwrap(); // Refresh the list after deletion
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      }
    }
  };

  return (
    <Container>
      <h2 className="text-center">Tout les années scolaires </h2>
      <FaPlus
        className="m-2"
        style={{ color: "blue", cursor: "pointer" }}
        onClick={() => {
          setShowModal(true);
        }}
      >
        Ajouter
      </FaPlus>

      <Table bordered hover size="sm" responsive>
        <thead>
          <tr className="text-center">
            <th>N</th>
            <th>Nom</th>
            <th>Date Debut</th>
            <th>Date Fin</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {schoolYears.length > 0 ? (
            schoolYears.map((school, index) => (
              <tr key={school.id} className="text-center">
                <td>{index + 1}</td>
                <td>{school?.name}</td>
                <td>{school.start}</td>
                <td>{school.end}</td>
                <td className="text-center">
                  <FaTrash
                    className="m-2"
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => handleDelete(school.id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                Aucun compte trouvé
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal-dark"
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une nouvelle année scolaire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formSchoolYearName">
              <Form.Label>Nom de l'année scolaire :</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={schoolYear.name}
                onChange={handleChange}
              />
              <Form.Text className="text-muted">Format: </Form.Text>
            </Form.Group>

            <Form.Group controlId="formStartDate">
              <Form.Label>Date de début :</Form.Label>
              <Form.Control
                type="date"
                name="start"
                value={schoolYear.start}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formEndDate">
              <Form.Label>Date de Fin :</Form.Label>
              <Form.Control
                type="date"
                name="end"
                value={schoolYear.end}
                onChange={handleChange}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="mt-3"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" />
                  {" Enregistrement..."}
                </>
              ) : (
                "Enregistrer"
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        className="modal-dark"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong style={{ color: "red" }}>
            {" "}
            Êtes-vous sûr de vouloir supprimer cette année scolaire ? Cela
            supprimera également toutes les données qui y sont liées, y compris
            les groupes, formations, étudiants, séances, etc.
          </strong>
          <br />
          <strong style={{ color: "red" }}>Attention !</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmModal(false)}
          >
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
