import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from '../../api/axios';

export const fetchArchivedTeachers = createAsyncThunk(
  'archivedTeachers/fetchArchivedTeachers',
  async ({ page = 1, pageSize = 10, searchTerm }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/teachers-archives`, {
        params: { page, pageSize, searchTerm },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error); 
    }
  }
);

export const deleteArchivedTeacher = createAsyncThunk(
  'archivedTeachers/deleteArchivedTeacher',
  async (teacherId, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/teachers-archives/${teacherId}`);
      return teacherId;
    } catch (error) {
      return rejectWithValue(error); 
    }
  }
);

const archivedTeachersSlice = createSlice({
  name: 'archivedTeachers',
  initialState: {
    archivedTeachers: [],
    loading: false,
    error: null,
    totalTeachers: 0,
    currentPage: 1,
    pageSize: 10,
  },
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchArchivedTeachers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchArchivedTeachers.fulfilled, (state, action) => {
        state.loading = false;
        state.archivedTeachers = action.payload.teachers;
        state.totalTeachers = action.payload.totalTeachers;
      })
      .addCase(fetchArchivedTeachers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })
      .addCase(deleteArchivedTeacher.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteArchivedTeacher.fulfilled, (state, action) => {
        state.loading = false;
        state.archivedTeachers = state.archivedTeachers.filter(teacher => teacher.id !== action.payload);
      })
      .addCase(deleteArchivedTeacher.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      });
  },
});

export const { setCurrentPage } = archivedTeachersSlice.actions;

export default archivedTeachersSlice.reducer;
