import axiosPrivate from "../api/axios";
import { useLayoutEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import { useSelector } from "react-redux";
let isRefreshing = false;
let failedQueue = [];
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};
const InterceptorsProvider = ({ children }) => {
  const refresh = useRefreshToken();
  const {accessToken} = useSelector((e) => e.auth);
  useLayoutEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          if (!isRefreshing) {
            isRefreshing = true;
            prevRequest.sent = true;
            try {
              const newAccessToken = await refresh();
              axiosPrivate.defaults.headers["Authorization"] = `Bearer ${newAccessToken}`;
              prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
              processQueue(null, newAccessToken);
              return axiosPrivate(prevRequest);
            } catch (err) {
              processQueue(err, null);
              return Promise.reject(err);
            } finally {
              isRefreshing = false;
            }
          }
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          }).then((token) => {
            prevRequest.headers["Authorization"] = `Bearer ${token}`;
            return axiosPrivate(prevRequest);
          }).catch((err) => Promise.reject(err));
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [accessToken, refresh]);

  return children;
};

export default InterceptorsProvider;
