import React from "react";
import "./Unauthorized.css"; 
import { Link } from "react-router-dom";

export const Unauthorized = () => {
  return (
    <div className="tout-for">
      <div className="cage401"></div>
      <h1 className="h1401"><span className="span401">401</span></h1>
      <Link className="button-unau" to="/">Accueil</Link>
    </div>
  );
};

