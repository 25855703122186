import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from '../../api/axios';


export const fetchStudents = createAsyncThunk(
  'groupStudents/fetchStudents',
  async (groupId, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/group-students/${groupId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const addStudent = createAsyncThunk(
  'groupStudents/addStudent',
  async (studentData, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post('/group-students', studentData);
      return response.data; 
    } catch (error) {
      return rejectWithValue({
        message: error.response?.data?.message || error.message,
        status: error.response?.status,
        data :error.response?.data
      });
    }
  }
);

export const updateStudent = createAsyncThunk(
  'groupStudents/updateStudent',
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`/group-students/${id}`, updatedData);
      return response.data; 
    }catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const deleteStudent = createAsyncThunk(
  'groupStudents/deleteStudent',
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/group-students/${id}`);
      return id; 
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

// Create the slice
const groupStudentsSlice = createSlice({
  name: 'groupStudents',
  initialState: {
    students: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStudents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.students = action.payload; 
      })
      .addCase(fetchStudents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; 
      })
      .addCase(addStudent.fulfilled, (state, action) => {
        state.students.push(action.payload);
      })
      .addCase(addStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.message
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        const index = state.students.findIndex(student => student.id === action.payload.id);
        if (index !== -1) {
          state.students[index] = action.payload; 
        }
      })
      .addCase(updateStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; 
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        const index = state.students.findIndex(student => student.id === action.payload);
        if (index !== -1) {
          state.students.splice(index, 1); 
        }
      })
      .addCase(deleteStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; 
      });
  },
});

// Export the reducer
export default groupStudentsSlice.reducer;
