import moment from "moment";
import React from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";

const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute of [0, 30]) {
      const time = moment().set({ hour, minute }).format("HH:mm");
      times.push(time);
    }
  }
  return times;
};

const capitalizeFirstLetter = (str) => {
  if (!str) return str;
  return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
};

const CustomTimePicker = ({ value, onChange }) => {
  const timeOptions = generateTimeOptions();
  return (
    <Form.Select value={value} onChange={(e) => onChange(e.target.value)}>
      {timeOptions.map((time) => (
        <option key={time} value={time}>
          {time}
        </option>
      ))}
    </Form.Select>
  );
};

const AddSessionModal = ({
  showModal,
  handleClose,
  startDate,
  setStartDate,
  startTime,
  setStartTime,
  endDate,
  setEndDate,
  endTime,
  setEndTime,
  repeatOption,
  isRemote,
  selectedRoomId,
  setSelectedRoomId,
  handleSaveSession,
  rooms,
  handleRemote,
  handleRepeatOptionChange,
  detailsTeaching,
  groupDetails,
  loading,
}) => {
  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Ajouter Nouvelle Séance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {(detailsTeaching?.choice?.teaching_method !== "At home" ||
            groupDetails?.choice?.teaching_method === "Group") && (
            <Row className="mb-4">
              {isRemote ? (
                <Col md={10}>
                  <Form.Group controlId="formRoom">
                    <Form.Select
                      aria-label="Select room"
                      value={selectedRoomId}
                      onChange={(e) => setSelectedRoomId(e.target.value)}
                    >
                      <option value="">Sélectionner Salle</option>
                      {rooms.map((room) => (
                        <option key={room.id} value={room.id}>
                          {capitalizeFirstLetter(room.name)}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              ) : (
                <Col md={10}>
                  <div>Séance à distance</div>
                </Col>
              )}
              <Col md={2} className="d-flex justify-content-end align-items-center">
                <label>
                  <input
                    type="checkbox"
                    className="btn-checkbox"
                    checked={!isRemote}
                    onChange={handleRemote}
                  />
                  <div className="btn-switch"></div>
                </label>
              </Col>
            </Row>
          )}

          <Row className="mb-4">
            <Col md={4}>
              <Form.Group controlId="formStartDate">
                <Form.Label>Date Début</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate || ""}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="formStartTime">
                <Form.Label>Heure Début</Form.Label>
                <CustomTimePicker value={startTime} onChange={setStartTime} />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formEndDate">
                <Form.Label>Date Fin</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate || ""}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="formEndTime">
                <Form.Label>Heure Fin</Form.Label>
                <CustomTimePicker value={endTime} onChange={setEndTime} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formSelectOption">
                <Form.Select
                  value={repeatOption}
                  onChange={handleRepeatOptionChange}
                >
                  <option value="">Pas de répétition</option>
                  <option value="Daily">Par Jour</option>
                  <option value="Weekly">Par Semaine</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
        <Button
          variant="primary"
          onClick={handleSaveSession}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Enregistrement...
            </>
          ) : (
            "Enregistrer"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSessionModal;
