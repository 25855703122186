import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchStudents,
  deleteStudent,
  updateStudent,
} from "../../Redux/groupStudents/GroupStudentsSlice";
import { fetchNextThreeSessions } from "../../Redux/groupSessions/groupSessions";
import { nextPayment } from "../../Redux/payment/paymentSlice";
import { Button, Modal, Table, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCreditCard, FaEdit, FaTrash } from "react-icons/fa";

export const GroupeEtudent = () => {
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const students = useSelector((state) => state.groupStudents.students);
  const status = useSelector((state) => state.groupStudents.status);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [studentToUpdate, setStudentToUpdate] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  // const [sessions, setSessions] = useState([]);

  const session = useSelector((s) => s.groupSessions.sessions);

  // Function to generate next payment
  const handleGenerateNextPayment = async (student) => {
    if (!student) {
      toast.error("Données de l'étudiant invalides.");
      return;
    }
    if (!student.session?.first_session) {
      toast.error("Cet élève n'a pas encore commencé ses études.");
      return;
    }
    try {
      await dispatch(nextPayment({ studentId: student.id })).unwrap();
      toast.success("Le prochain paiement a été généré avec succès !");
    } catch (error) {
      const status = error?.status || error?.response?.status;

      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    }
  };

  useEffect(() => {
    const loadStudents = async () => {
      try {
        await dispatch(fetchStudents(groupId)).unwrap();
      } catch (err) {
        toast.error("Error fetching students.");
      }
    };
    loadStudents();

    const loadSessions = async () => {
      try {
        await dispatch(fetchNextThreeSessions(groupId)).unwrap();
      } catch (err) {
        toast.error("Error fetching sessions.");
      }
    };
    loadSessions();
  }, [dispatch, groupId]);

  const handleShowDeleteModal = (id) => {
    setStudentToDelete(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setStudentToDelete(null);
  };

  const handleDeleteStudent = async () => {
    if (studentToDelete) {
      try {
        await dispatch(deleteStudent(studentToDelete)).unwrap();
        toast.success("Student deleted successfully!");
      } catch (error) {
        const status = error?.status || error?.response?.status;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message || "An unexpected error occurred.");
            break;
        }
      } finally {
        handleCloseDeleteModal();
      }
    }
  };

  const handleShowUpdateModal = (student) => {
    setStudentToUpdate(student);
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setStudentToUpdate(null);
    setSelectedSession(null);
  };

  const handleUpdateStudent = async () => {
    if (studentToUpdate && selectedSession) {
      try {
        await dispatch(
          updateStudent({
            id: studentToUpdate.id,
            updatedData: {
              group_id: groupId,
              student_id: studentToUpdate.id,
              first_session: selectedSession,
            },
          })
        ).unwrap();
        dispatch(fetchStudents(groupId));
        toast.success("Student updated successfully!");
      } catch (error) {
        const status = error?.status || error?.response?.status;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message || "An unexpected error occurred.");
            break;
        }
      } finally {
        handleCloseUpdateModal();
      }
    } else {
      toast.error("Please select a session.");
    }
  };

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  return (
    <div>
      <h2>Étudiant dans le groupe</h2>
      <Table bordered hover size="sm">
        <thead>
          <tr className="text-center">
            <th>Nom</th>
            <th>Premier Séance</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {students.length > 0 ? (
            students.map((student) => (
              <tr key={student.id} className="text-center">
                <td>{capitalizeFirstLetter(student.student?.name)}</td>
                <td>
                  {student.session?.first_session
                    ? new Date(student.session.first_session)
                        .toLocaleDateString("fr-FR", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                        .replace(/^([a-z])/, (match) => match?.toUpperCase())
                    : "Non défini pour le moment"}
                </td>
                <td>
                  <FaTrash
                    className="m-2 "
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => handleShowDeleteModal(student.id)}
                  />
                  <FaEdit
                    className="m-2 "
                    style={{ color: "green", cursor: "pointer" }}
                    onClick={() => handleShowUpdateModal(student)}
                  />
                  <FaCreditCard
                    className="m-2"
                    style={{
                      color: student.session?.first_session
                        ? "#28a745"
                        : "gray",
                      cursor: student.session?.first_session
                        ? "pointer"
                        : "not-allowed",
                    }}
                    onClick={() => handleGenerateNextPayment(student)}
                    disabled={!student.session?.first_session}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="3"
                style={{ textAlign: "center", backgroundColor: "red" }}
              >
                Non trouvé
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation suppresion </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet étudiant ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDeleteStudent}>
            supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Update Student Modal */}
      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier Étudiant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nom Étudiant </Form.Label>
              <Form.Control
                type="text"
                value={studentToUpdate?.student?.name || ""}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Sélectionner Premier Seance:</Form.Label>
              <div className="groupSeance mt-4">
                {session.map((session) => (
                  <div
                    key={session.id}
                    className={`seance ${
                      selectedSession === session.id ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedSession(session.id);
                    }}
                  >
                    <div className="date">
                      {new Date(session.start).toLocaleDateString()}
                    </div>
                    <div className="day">
                      {new Date(session.start).toLocaleString("fr-FR", {
                        weekday: "long",
                      })}
                    </div>
                    <div className="time">
                      {new Date(session.start).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleUpdateStudent}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
