import React, { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  Form,
  Row,
  Table,
  Modal,
  Button,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllPayments,
  deletePayment,
  makePartialPayment,
  makePay,
  deletePartialPayment,
} from "../../Redux/payment/paymentSlice";
import { FaEye, FaTrash } from "react-icons/fa";
import "./paiement.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const Paiement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    payments,
    paymentMonths,
    totalPayments,
    pageSize = 10,
  } = useSelector((state) => state.payments);

  const [showModal, setShowModal] = useState(false);

  const [paymentIdToDelete, setPaymentIdToDelete] = useState(null);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [amount, setAmount] = useState("");
  const [originAmount, setOriginAmount] = useState("");
  const [date, setDate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [showTarifeModal, setShowTarifeModal] = useState(false);
  const [showFreeModal, setShowFreeModal] = useState(false);
  const { selectedYear } = useSelector((state) => state.schoolYears);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedPaymentMonth, setSelectedPaymentMonth] = useState("");
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedPaymentDetails, setSelectedPaymentDetails] = useState(null);
  const [showPartialDeleteModal, setShowPartialDeleteModal] = useState(false);
  const [isLoadingC, SetisLoadingC] = useState(false);
  const [isLoadingT, SetisLoadingT] = useState(false);
  const [isLoadingG, SetisLoadingG] = useState(false);
  const [partialPaymentIdToDelete, setPartialPaymentIdToDelete] =
    useState(null);

  const handleViewDetails = (payment) => {
    setShowDetailModal(true);
    setSelectedPaymentDetails(payment);
  };

  useEffect(() => {
    const fetchDataa = async () => {
      try {
        if (selectedYear) {
          await dispatch(
            fetchAllPayments({
              yearId: selectedYear,
              page: currentPage,
              pageSize,
              status: statusFilter,
              paymentMonth: selectedPaymentMonth,
            })
          ).unwrap();
        }
      } catch (error) {}
    };
    fetchDataa();
  }, [
    dispatch,
    selectedYear,
    currentPage,
    pageSize,
    statusFilter,
    selectedPaymentMonth,
  ]);

  const handlePartialDelete = async () => {
    if (partialPaymentIdToDelete) {
      try {
        await dispatch(deletePartialPayment(partialPaymentIdToDelete)).unwrap();
        toast.success("Paiement partiel supprimé avec succès.");
        if (selectedPaymentDetails) {
          const updatedPartials = selectedPaymentDetails.partials.filter(
            (partial) => partial.id !== partialPaymentIdToDelete
          );
          setSelectedPaymentDetails({
            ...selectedPaymentDetails,
            partials: updatedPartials,
          });
        }

        // Rafraîchir les paiements
        dispatch(
          fetchAllPayments({
            yearId: selectedYear,
            page: currentPage,
            pageSize,
            paymentMonth: selectedPaymentMonth,
          })
        );
      } catch (error) {
        toast.error("Failed to delete partial payment.");
      } finally {
        setShowPartialDeleteModal(false);
        setPartialPaymentIdToDelete(null);
      }
    }
  };

  const confirmPartialDelete = (partialId) => {
    setPartialPaymentIdToDelete(partialId);
    setShowPartialDeleteModal(true);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    return now
      .toLocaleString("sv-SE", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      .slice(0, 16)
      .replace(" ", "T");
  };

  useEffect(() => {
    setDate(getCurrentDateTime());
  }, []);
  const handleDelete = async () => {
    if (paymentIdToDelete) {
      try {
        await dispatch(deletePayment(paymentIdToDelete)).unwrap();
        const paymentsCount = payments.filter(
          (payment) => payment.id !== paymentIdToDelete
        ).length; 
        if(!paymentsCount){
          const foundPaymentMonths = paymentMonths.find(
            (payment) => payment.payment_month !== (selectedPaymentMonth || paymentMonths[0]?.payment_month)
          );
          if(foundPaymentMonths) setSelectedPaymentMonth(foundPaymentMonths.payment_month)
          
        }else{
          dispatch(
            fetchAllPayments({
              yearId: selectedYear,
              page: currentPage,
              pageSize,
              paymentMonth: selectedPaymentMonth,
            })
          );
        }
        toast.success("Paiement supprimé avec succès.");

        const newTotalPayments = totalPayments - 1;
        if (newTotalPayments % pageSize === 0 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        
      } catch (error) {
        const status = error?.status || error?.response?.status;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message || "An unexpected error occurred.");
            break;
        }
      } finally {
        setShowModal(false);
        setPaymentIdToDelete(null);
      }
    }
  };

  const confirmDelete = (paymentId) => {
    setPaymentIdToDelete(paymentId);
    setShowModal(true);
  };

  const getcaluculerrest = () => {
    const payment = payments.find((month) => month.id === selectedPaymentId);

    if (payment) {
      const totalAmount = payment.amount;
      const totalPartials =
        payment.partials?.reduce((sum, partial) => sum + partial.amount, 0) ||
        0;
      const remainingAmount = totalAmount - totalPartials;

      return remainingAmount;
    } else {
      return 0;
    }
  };

  const handleCompletePayment = (payment) => {
    setSelectedPaymentId(payment.id);
    setOriginAmount(payment.amount);
    setDate(getCurrentDateTime());
    setShowCompleteModal(true);
  };

  const handleCompleteSubmit = async () => {
    if (!amount || !date || !paymentMethod) {
      toast.error("Veuillez remplir tous les champs requise.");
      return;
    }
    const remainingAmount = getcaluculerrest();

    if (amount > originAmount) {
      toast.error(
        "Le montant payé partiellement ne peut pas être supérieur au montant spécifié"
      );
      return;
    }
    if (amount > remainingAmount) {
      toast.error(
        "Le montant payé partiellement ne peut pas être supérieur au montant restant."
      );
      return;
    }

    const localDate = new Date(date);
    const formattedDate = localDate
      .toISOString()
      .slice(0, 16)
      .replace(/T/, " ")
      .replace(/-/g, "/");

    const paymentData = {
      amount: amount,
      paid_on: formattedDate,
      payment_method: paymentMethod,
    };
    try {
      SetisLoadingC(true);
      await dispatch(
        makePartialPayment({
          payment_id: selectedPaymentId,
          amountData: paymentData,
        })
      ).unwrap();

      dispatch(
        fetchAllPayments({
          yearId: selectedYear,
          page: currentPage,
          pageSize,
          paymentMonth: selectedPaymentMonth,
        })
      );

      toast.success("Paiement partiel effectué avec succès.");

      setShowCompleteModal(false);
      setAmount("");
      setDate("");
      setPaymentMethod("");
    } catch (error) {
      const status = error?.status || error?.response?.status;

      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    } finally {
      SetisLoadingC(false);
    }
  };

  const handleTarifePayment = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setDate(getCurrentDateTime());
    setShowTarifeModal(true);
  };

  const handleTarifeSubmit = async () => {
    if (!date || !paymentMethod) {
      toast.error("Veuillez remplir tous les champs  e.");
      return;
    }

    const localDate = new Date(date);
    const formattedDate = localDate
      .toISOString()
      .slice(0, 16)
      .replace(/T/, " ")
      .replace(/-/g, "/");

    try {
      SetisLoadingT(true);
      await dispatch(
        makePay({
          paymentId: selectedPaymentId,
          paid: true,
          paymentMethod: paymentMethod,
          paidOn: formattedDate,
        })
      ).unwrap();

      dispatch(
        fetchAllPayments({
          yearId: selectedYear,
          page: currentPage,
          pageSize,
          paymentMonth: selectedPaymentMonth,
        })
      );

      toast.success("Paiement tarifaire créé avec succès.");

      setShowTarifeModal(false);
      setDate("");
      setPaymentMethod("");
    } catch (error) {
      const status = error?.status || error?.response?.status;

      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    } finally {
      SetisLoadingT(false);
    }
  };

  const handleFreePayment = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setShowFreeModal(true);
  };

  const handleFreeSubmit = async () => {
    try {
      SetisLoadingG(true);
      await dispatch(
        makePay({
          paymentId: selectedPaymentId,
          paid: false,
        })
      ).unwrap();

      toast.success("Paiement gratuit créé avec succès..");

      dispatch(
        fetchAllPayments({
          yearId: selectedYear,
          page: currentPage,
          pageSize,
          paymentMonth: selectedPaymentMonth,
        })
      );

      setShowFreeModal(false);
    } catch (error) {
      const status = error?.status || error?.response?.status;

      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    } finally {
      SetisLoadingG(false);
    }
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(totalPayments / pageSize);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  const handleCloseModalPartial = () => {
    setShowCompleteModal(false);
    setAmount();
    setPaymentMethod();
    setDate();
  };

  const handleCloseModalComplet = () => {
    setShowTarifeModal(false);
    setPaymentMethod();
    setDate();
  };

  return (
    <div>
      <Row className="align-items-center ">
        <Col xs={12} md={6} className="text-md-start text-center">
          <h3>Tableau des paiements</h3>
        </Col>
      </Row>
      <Row className="justify-content-end mb-3">
        <Col xs={12} md={5} className="text-end">
          <Form.Select
            style={{ width: "270px", display: "inline-block" }}
            size="sm"
            value={selectedPaymentMonth || paymentMonths[0]?.payment_month}
            onChange={(e) => setSelectedPaymentMonth(e.target.value)}
          >
            <option value="">Sélectionnez un mois de paiement</option>
            {paymentMonths.map((month) => (
              <option key={month.payment_month} value={month.payment_month}>
                {moment(month.payment_month).format("YYYY/MM/DD")}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs={12} md={3} className="text-end">
          <Form.Select
            style={{ width: "270px", display: "inline-block" }}
            size="sm"
            value={statusFilter}
            onChange={handleStatusChange}
          >
            <option value="">Tous les paiements des étudiants</option>
            <option value="0">Les paiements non effectué</option>
            <option value="1">Les paiements effectué</option>
            <option value="2">Les paiements restant</option>
          </Form.Select>
        </Col>
      </Row>

      <div className="table-responsive">
        <Table bordered>
          <thead>
            <tr className="text-center">
              <th>N</th>
              <th>Étudiant</th>
              <th>Formation</th>
              <th>Montant</th>
              <th>Reste</th>
              <th>Paye</th>
              <th>Etat</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => {
              const totalPartials =
                payment.partials?.reduce(
                  (sum, partial) => sum + partial.amount,
                  0
                ) || 0;
              const remainingAmount = payment.amount - totalPartials;

              return (
                <tr key={payment.id} className="text-center">
                  <td>{index + 1}</td>
                  <td>
                    {capitalizeFirstLetter(payment.student?.nom)}{" "}
                    {capitalizeFirstLetter(payment.student?.prenom)}
                  </td>
                  <td>{capitalizeFirstLetter(payment.formation?.name)}</td>
                  <td>{payment.amount} Dh</td>
                  <td>{remainingAmount} Dh</td>
                  <td>{totalPartials} Dh</td>
                  <td>
                    {payment.status === 0 ? (
                      <span
                        className="badge bg-danger"
                        style={{ width: "70px", textAlign: "center" }}
                      >
                        Impayé
                      </span>
                    ) : payment.status === 1 ? (
                      <span
                        className="badge bg-success"
                        style={{ width: "70px", textAlign: "center" }}
                      >
                        Complété
                      </span>
                    ) : payment.status === 2 ? (
                      <span
                        className="badge bg-warning"
                        style={{ width: "70px", textAlign: "center" }}
                      >
                        Restant
                      </span>
                    ) : null}
                  </td>

                  <td>{moment(payment.invoice_date).format("YYYY/MM/DD")}</td>

                  <td>
                    <div className="text-center d-flex justify-content-center">
                      <FaTrash
                        className="mt-2"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => confirmDelete(payment.id)}
                      />

                      <FaEye
                        className="m-2"
                        style={{ color: "green", cursor: "pointer" }}
                        onClick={() => handleViewDetails(payment)}
                      />

                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          size="sm"
                          style={{ border: "none" }}
                        />
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              if (payment.status === 1) {
                                toast.warning(
                                  "Paiement déjà complété. Impossible de modifier."
                                );
                              } else {
                                handleCompletePayment(payment);
                              }
                            }}
                          >
                            Paiement Partiel
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              if (payment.status === 1) {
                                toast.warning(
                                  "Paiement déjà complété. Impossible de modifier."
                                );
                              } else {
                                handleTarifePayment(payment.id);
                              }
                            }}
                          >
                            Paiement Complet
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              if (payment.status === 1) {
                                toast.warning(
                                  "Paiement déjà complété. Impossible de modifier."
                                );
                              } else {
                                handleFreePayment(payment.id);
                              }
                            }}
                          >
                            Paiement Gratuit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-center my-4">
        <Pagination>
          <Pagination.First
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          />

          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}

          <Pagination.Next
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>

      {/* Confirmation Delete Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer ce paiement ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Completing Payment */}
      <Modal show={showCompleteModal} onHide={handleCloseModalPartial}>
        <Modal.Header closeButton>
          <Modal.Title>Paiement Partiel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Entrez le montant Partiel :</Form.Label>
            <Form.Control
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="">Choisissez la méthode de paiement</option>
              <option value="Cash">Espèce</option>
              <option value="Western union/CashPlus">CashPlus</option>
              <option value="Bank transfer">Virement Bancaire</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Sélectionnez la date :</Form.Label>
            <Form.Control
              type="datetime-local"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalPartial}>
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={handleCompleteSubmit}
            disabled={isLoadingC}
          >
            {isLoadingC ? (
              <>
                <Spinner animation="border" size="sm" /> {"Enregistrement..."}
              </>
            ) : (
              "Enregistrer"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Tarife Payment */}
      <Modal show={showTarifeModal} onHide={handleCloseModalComplet}>
        <Modal.Header closeButton>
          <Modal.Title>Paiement Complet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mt-4">
            <Form.Select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="">Choisissez la méthode de paiement</option>
              <option value="Cash">Espèce</option>
              <option value="Western union/CashPlus">CashPlus</option>
              <option value="Bank transfer">Virement Bancaire</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Sélectionnez la date :</Form.Label>
            <Form.Control
              type="datetime-local"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalComplet}>
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={handleTarifeSubmit}
            disabled={isLoadingT}
          >
            {isLoadingT ? (
              <>
                <Spinner animation="border" size="sm" />
                {"Enregistrement..."}
              </>
            ) : (
              "Enregistrer"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal for free payement */}
      <Modal show={showFreeModal} onHide={() => setShowFreeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Êtes-vous sûr de vouloir Paiement 0% ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFreeModal(false)}>
            Annuler
          </Button>
          <Button
            variant="danger"
            onClick={handleFreeSubmit}
            disabled={isLoadingG}
          >
            {isLoadingG ? (
              <>
                <Spinner animation="border" size="sm" />
                {"Enregistrement..."}
              </>
            ) : (
              "Enregistrer"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal for details partails  */}
      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Détails des Paiements Partiels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPaymentDetails && (
            <table className="table">
              <thead>
                <tr className="text-center">
                  <th>N</th>
                  <th>Date </th>
                  <th>Méthode </th>
                  <th>Montant</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedPaymentDetails.partials?.map((partial, index) => (
                  <tr key={index} className="text-center">
                    <td>{index + 1}</td>
                    <td>
                      {moment(partial.paid_on).format("YYYY/MM/DD HH:mm:ss")}
                    </td>

                    <td>
                      {" "}
                      {partial.payment_method === "Cash"
                        ? "Espèce"
                        : partial.payment_method === "Western union/CashPlus"
                        ? "CashPlus"
                        : partial.payment_method === "Bank transfer"
                        ? "Virement Bancaire"
                        : partial.payment_method}
                    </td>
                    <td>{partial.amount} Dh</td>
                    <td>
                      <FaTrash
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => confirmPartialDelete(partial.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <Button variant="secondary" onClick={() => setShowDetailModal(false)}>
            Fermer
          </Button>
        </Modal.Body>
      </Modal>

      {/* modal de confiramtion delete markPartial */}
      <Modal
        show={showPartialDeleteModal}
        onHide={() => setShowPartialDeleteModal(false)}
        size="sm"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer ce paiement partiel ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowPartialDeleteModal(false)}
          >
            Annuler
          </Button>
          <Button variant="danger" onClick={handlePartialDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
