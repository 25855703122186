import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

const initialState = {
  payments: [],
  loading: false,
  error: null,
  totalPayments: 0,
  currentPage: 1,
  pageSize: 10,
  totalPages: 0,
  paymentMonths: [],
};

export const fetchAllPayments = createAsyncThunk(
  "payments/fetchAll",
  async (
    { yearId, status, page = 1, pageSize = 10, paymentMonth=[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.get(`/payments/${yearId}`, {
        params: { status, page, pageSize, paymentMonth },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const makePay = createAsyncThunk(
  "payments/create",
  async ({ paymentId, paid, paymentMethod, paidOn }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/pay/${paymentId}`, {
        params: {
          paid,
          payment_method: paymentMethod,
          paid_on: paidOn,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const makePartialPayment = createAsyncThunk(
  "payments/partial",
  async ({ payment_id, amountData }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `/partial-payment/${payment_id}`,
        amountData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const deletePayment = createAsyncThunk(
  "payments/delete",
  async (paymentId, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/payments/${paymentId}`);
      return paymentId;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const nextPayment = createAsyncThunk(
  "payments/nextPayment",
  async ({ studentId }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `/next-payments/${studentId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const deletePartialPayment = createAsyncThunk(
  "payments/deletePartial",
  async (paymentId, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/partial-payment/${paymentId}`);
      return paymentId;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    resetPayments: (state) => {
      state.payments = [];
      state.totalPayments = 0;
      state.currentPage = 1;
      state.totalPages = 0;
    },

    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPayments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllPayments.fulfilled, (state, action) => {
        const { totalPayments, payments, paymentMonths } = action.payload;
        state.loading = false;
        state.payments = payments;
        state.totalPayments = totalPayments;
        state.paymentMonths = paymentMonths;
      })
      .addCase(fetchAllPayments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(makePay.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(makePay.fulfilled, (state, action) => {
        state.loading = false;
        state.payments.push(action.payload);
      })
      .addCase(makePay.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(makePartialPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(makePartialPayment.fulfilled, (state, action) => {
        const updatedPartialPayment = action.payload;
        const index = state.payments.findIndex(
          (payment) => payment.id === updatedPartialPayment.id
        );
        if (index !== -1) {
          state.payments[index] = updatedPartialPayment;
        } else {
          state.payments.push(updatedPartialPayment);
        }
      })
      .addCase(makePartialPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = state.payments.filter(
          (payment) => payment.id !== action.payload
        );
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePartialPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePartialPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = state.payments.filter(
          (payment) => payment.id !== action.payload
        );
      })
      .addCase(deletePartialPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(nextPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(nextPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "success";
        state.status = action.payload;
      })
      .addCase(nextPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const { resetPayments, setCurrentPage } = paymentSlice.actions;

export default paymentSlice.reducer;
