import React from "react";
import "./Forbidden.css"; 

export const Forbidden = () => {
  return (
    <div className="tout-for">

<div class="cage403"></div>
<h1 className="h1403"><span className="span403">403</span></h1>

    </div>
  );
};
