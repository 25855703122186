import React, { useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import {
  fetchOneTeachingStudent,
} from "../../Redux/teachingStudents/teachingStudentSlice";
import { useDispatch } from "react-redux";

const EventDetailsTeaching = ({ show, handleClose, detailsTeaching }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUpdatedGroupData = async () => {
      if (detailsTeaching?.id) {
        await dispatch(fetchOneTeachingStudent(detailsTeaching.id));
      }
    };

    fetchUpdatedGroupData();
  }, [dispatch, detailsTeaching?.id]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Détails de l'enseignement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {detailsTeaching && (
          <div>
            <Row className="mb-3">
              <Col>
                <p style={{ fontSize: "15px" }}>
                  <span >Nom : </span>
                  <strong>{detailsTeaching.student?.nom}</strong>
                </p>
              </Col>
              <Col>
                <p style={{ fontSize: "15px" }}>
                  <span >Prof :</span>
                  <strong>
                    {detailsTeaching.teacher?.nom}&nbsp;
                    {detailsTeaching.teacher?.prenom}
                  </strong>
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <p style={{ fontSize: "15px" }}>
                  <span >Niveau :</span>
                  <strong>
                    {detailsTeaching.level?.name
                      ? detailsTeaching.level?.name?.toUpperCase()
                      : "non existant"}
                  </strong>
                </p>
              </Col>
              <Col>
                <p style={{ fontSize: "15px" }}>
                  <span >Durée Formation :</span>
                  <strong>
                  {detailsTeaching.choice?.formation_duration === 0
                      ? "Par mois"
                      : detailsTeaching.choice?.formation_duration + " Mois"}
                  </strong>
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <p style={{ fontSize: "15px" }}>
                  <span >Durée Séance :</span>
                  <strong>{detailsTeaching.choice?.session_duration} H</strong>
                </p>
              </Col>
              <Col>
                <p style={{ fontSize: "15px" }}>
                  <span > Nbr Séance / Semaine :</span>
                  <strong>{detailsTeaching.choice?.session_nb}</strong>
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <p style={{ fontSize: "15px" }}>
                  <span >Méthode :</span>
                  <strong>
                    {detailsTeaching.choice?.teaching_method === "At home"
                      ? "À Domicile"
                      : detailsTeaching.choice?.teaching_method === "Group"
                      ? "Groupe"
                      : detailsTeaching.choice?.teaching_method === "Individual"
                      ? "Individuel"
                      : detailsTeaching.choice?.teaching_method}
                  </strong>
                </p>
              </Col>
              <Col>
                <p style={{ fontSize: "15px" }}>
                  <span >Prix :</span>
                  <strong>{detailsTeaching.choice?.price} Dh</strong>
                </p>
              </Col>
            </Row>
            <Col>
              <p style={{ fontSize: "15px" }}>
                <span > Etat :</span>
                <span
                  className={`badge ${
                    detailsTeaching.active ? "bg-success" : "bg-danger"
                  }`}
                >
                  {detailsTeaching.active ? "Active" : "Inactive"}
                </span>
              </p>
            </Col>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventDetailsTeaching;
