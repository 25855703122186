import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { refresh } from "../Redux/Auth/authSlice";

const  useRefreshToken = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const refreshAccessToken = async () => {
    try {
      const {accessToken} = await dispatch(refresh()).unwrap();
      return accessToken
    } catch (error) {
      navigate('/login')
      throw error;
    }
  };

  return refreshAccessToken;
};

export default useRefreshToken;
    