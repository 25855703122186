import React from "react";
import styled from "styled-components";
import whatssap from "../../assets/img/contact/whatssap.png";
import gmail from "../../assets/img/contact/gmail.png";
import TopNavbar from "../Nav/TopNavbar";

export const Contact = () => {
  return (
    <StyledDiv>
        <TopNavbar/>
      <Section>
        <Title>Contact</Title>
        <IconContainer>
          <IconWrapper>
            <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer">
              <Icon src={whatssap} alt="Icon 1" />
            </a>
            <IconName>WhatsApp 1</IconName>
          </IconWrapper>
          <IconWrapper>
            <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer">
              <Icon src={whatssap} alt="Icon 2" />
            </a>
            <IconName>WhatsApp 2</IconName>
          </IconWrapper>
          <IconWrapper>
            <a href="mailto:example@gmail.com" target="_blank" rel="noopener noreferrer">
              <Icon src={gmail} alt="Icon 3" />
            </a>
            <IconName>Gmail</IconName>
          </IconWrapper>
        </IconContainer>
      </Section>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  background-color: #e2e2f6;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Section = styled.section`
  background: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80%;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */

  @media (max-width: 768px) {
    flex-direction: column; /* Change to column layout on small screens */
    align-items: center; /* Center items in column layout */
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  width: 150px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const IconName = styled.span`
  font-size: 16px;
  color: #333;
`;
