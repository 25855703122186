import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchFormationsWithCategories } from "../../Redux/formation/formationSlice";
import {
  fetchLevelsByFormation,
  resetLevels,
} from "../../Redux/levels/levelsSlice";
import {
  addTeachingChoice,
  updateTeachingChoice,
  deleteTeachingChoice,
  fetchTeachingChoices,
  setCurrentPage,
} from "../../Redux/choix/teachingChoiceSlice";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import "./choix.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useDebounce } from "use-debounce";

export const Choix = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedFormation, setSelectedFormation] = useState("");
  const [selectedFormationName, setSelectedFormationName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 600);
  const [selectedTeachingMethod, setSelectedTeachingMethod] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    formation_id: "",
    level_id: "",
    teaching_method: "",
    formation_duration: "",
    session_nb: "",
    session_duration: "",
    price: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const formationsWithCategories = useSelector(
    (state) => state.formations.formationsWithCategories
  );
  const levelsState = useSelector((state) => state.levels.levels);
  const { currentPage, pageSize, totalTechingChoices, status } = useSelector(
    (state) => state.teachingChoices
  );

  const teachingChoices = useSelector(
    (state) => state.teachingChoices.teachingChoices
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(
          fetchTeachingChoices({
            page: currentPage,
            pageSize,
            teaching_method: selectedTeachingMethod,
            formation: selectedFormationName,
          })
        );
      } catch (error) {
        const status = error?.status || error?.response?.status;
        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      }
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    selectedTeachingMethod,
    selectedFormationName,
    pageSize,
    navigate,
  ]);

  const handleSearchmethodeChange = (event) => {
    const { value } = event.target;
    if (value === "all") {
      setSelectedTeachingMethod("");
    } else {
      setSelectedTeachingMethod(value);
    }
    dispatch(setCurrentPage(1));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(
          fetchFormationsWithCategories({ searchTerm: debouncedSearchTerm })
        );
      } catch (error) {
        const status = error?.status || error?.response?.status;
        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      }
    };

    fetchData();
  }, [dispatch, navigate, debouncedSearchTerm]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedFormation) {
          dispatch(fetchLevelsByFormation(selectedFormation));
        }
      } catch (error) {
        const status = error?.status || error?.response?.status;
        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      }
    };

    fetchData();
  }, [dispatch, selectedFormation, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "formation_id") {
      setSelectedFormation(value);
      dispatch(fetchLevelsByFormation(value));
    }

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleShowModal = (teachingChoice = null) => {
    if (teachingChoice) {
      setEditMode(true);
      setEditId(teachingChoice.id);
      setFormData({
        formation_id: teachingChoice.formation_id,
        level_id: teachingChoice.level_id,
        teaching_method: teachingChoice.teaching_method,
        session_nb: teachingChoice.session_nb,
        session_duration: teachingChoice.session_duration,
        price: teachingChoice.price,
        formation_duration:
          teachingChoice.formation_duration === 0 ? "mois" : "number",
      });
    } else {
      setEditMode(false);
      setFormData({
        formation_id: "",
        level_id: "",
        teaching_method: "",
        formation_duration: "",
        session_nb: "",
        session_duration: "",
        price: "",
      });
    }
    dispatch(resetLevels());
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      formation_id: "",
      level_id: "",
      teaching_method: "",
      formation_duration: "",
      session_nb: "",
      session_duration: "",
      price: "",
    });
    dispatch(resetLevels());
  };

  const validateFormData = (data) => {
    const newErrors = {};

    // Vérification de la formation
    if (!data.formation_id) {
      newErrors.formation_id = "La formation sélectionnée est requise.";
    }

    // Vérification de la méthode d'enseignement
    if (!data.teaching_method) {
      newErrors.teaching_method = "La méthode d'enseignement est requise.";
    }

    if (!data.durationType) {
      newErrors.formation_duration = "choisse un duree de formation.";
    }

    // Vérification de la durée de formation
    if (data.durationType === "number") {
      if (
        !data.formation_duration ||
        isNaN(parseFloat(data.formation_duration)) ||
        parseFloat(data.formation_duration) <= 0 ||
        !/^\d{1,2}$/.test(data.formation_duration)
      ) {
        newErrors.formation_duration =
          "Accepte uniquement à partir de 1 mois : c'est-à-dire plus de 1 et représentant le nombre de mois et pas depasse 12 mois.";
      }
    } else if (data.durationType === "mois") {
      if (data.formation_duration !== 0) {
        newErrors.formation_duration =
          "La durée de la formation doit être égale à 0.";
      }
    }

    // Vérification du nombre de sessions
    if (!data.session_nb) {
      newErrors.session_nb = "Le nombre de séance  est requise.";
    } else if (isNaN(parseFloat(data.session_nb))) {
      newErrors.session_nb = "Le nombre de séances doit être un nombre valide.";
    } else if (data.session_nb <= 0 || data.session_nb % 0.5 !== 0) {
      newErrors.session_nb =
        "Le nombre de séances doit être supérieur à zéro et un multiple de 0,5 .";
    }

    // Vérification de la durée de session
    if (!data.session_duration) {
      newErrors.session_duration = "Numéro de séance is required.";
    } else if (isNaN(parseFloat(data.session_duration))) {
      newErrors.session_duration =
        "Le numéro de séance doit être un nombre valide.";
    } else if (data.session_duration < 0.5) {
      newErrors.session_duration =
        "Le numéro de séance doit être d'au moins 0,5.";
    } else if (/\,/.test(data.session_duration)) {
      newErrors.session_duration =
        "Le numéro de séance ne doit pas contenir de virgule (,). Utilisez un point (.) pour les décimales.";
    }

    // Vérification du prix
    if (!data.price) {
      newErrors.price = "Le prix est requise.";
    } else if (isNaN(parseFloat(data.price))) {
      newErrors.price = "Le prix doit être un nombre valide.";
    } else if (data.price < 0) {
      newErrors.price = "Le prix doit être une valeur non négative.";
    }

    return newErrors;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateFormData(formData);

    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((error) => toast.error(error));
      return;
    }

    // Convert formData values to lowercase
    // const lowerCasedFormData = Object.keys(formData).reduce((acc, key) => {
    //   acc[key] = formData[key] ? formData[key].toString().toLowerCase() : formData[key];
    //   return acc;
    // }, {});

    try {
      setIsLoading(true);
      const dataToSend = { ...formData };

      if (!dataToSend.level_id) {
        delete dataToSend.level_id;
      }
      if (editMode) {
        await dispatch(
          updateTeachingChoice({ id: editId, updatedData: dataToSend })
        ).unwrap();
        toast.success("Choix d'enseignement mis à jour avec succès!");
      } else {
        await dispatch(addTeachingChoice(dataToSend)).unwrap();
        toast.success("Choix d'enseignement ajouté avec succès!");
      }
      dispatch(
        fetchTeachingChoices({
          page: currentPage,
          pageSize,
          teaching_method: selectedTeachingMethod,
          formation: selectedFormationName,
        })
      );
      dispatch(setCurrentPage(1));
      handleCloseModal();
      dispatch(resetLevels());
    } catch (error) {
      const status = error?.status || error?.response?.status;
      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message);
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowConfirmDelete(true);
  };

  const confirmDelete = async () => {
    try {
      await dispatch(deleteTeachingChoice(deleteId)).unwrap();

      dispatch(
        fetchTeachingChoices({
          page: currentPage,
          pageSize,
          teaching_method: selectedTeachingMethod,
          formation: selectedFormationName,
        })
      );

      if (teachingChoices.length === 0 && currentPage > 1) {
        dispatch(setCurrentPage(currentPage - 1));
      }

      toast.success("Le choix d'enseignement a été supprimé avec succès.");
    } catch (error) {
      const status = error?.status || error?.response?.status;
      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        case 400:
          toast.error(
            "vous ne pouvez pas le supprimer si vous utilisez cette choix ."
          );
          break;
        default:
          toast.error(error.message);
          break;
      }
    } finally {
      setShowConfirmDelete(false);
    }
  };

  const handlePageChange = (page) => {
    if (page !== currentPage && page > 0 && page <= totalPages) {
      dispatch(setCurrentPage(page));
      dispatch(
        fetchTeachingChoices({
          page,
          pageSize,
        })
      );
    }
  };

  const totalPages = Math.ceil(totalTechingChoices / pageSize);

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  const handleSearchformationChange = (selectedOption) => {
    const value = selectedOption?.value;
    setSelectedFormationName(value);
    dispatch(setCurrentPage(1));
  };

  const generateTimeOptions = () => {
    const times = [];
    let time = 1; 
    while (time <= 10) {
      const hours = String(Math.floor(time / 2)).padStart(2, "0");
      const minutes = time % 2 === 0 ? "00" : "30";
      times.push(`${hours}:${minutes}`);
      time++;
    }
    return times;
  };

  return (
    <>
      <Row className="align-items-center ">
        <Col xs={12} md={6} className="text-md-start text-center">
          <h3>Tableaux des choix</h3>
        </Col>
      </Row>
      <Row className="align-items-center mb-1">
        <Col
          xs={12}
          md={4}
          className="d-flex justify-content-center justify-content-xl-start me-md-auto  mb-md-0"
        >
          <FaPlus
            className="fa-plus"
            style={{ fontSize: "20px", color: "blue", size: "20px" }}
            variant="primary"
            onClick={() => handleShowModal()}
          />
        </Col>
        <Col xs={12} md={3} className="mb-md-0 d-flex justify-content-center">
          <Select
            aria-label="Select Formation"
            options={[
              { value: "", label: "Tous les choix" },
              ...formationsWithCategories?.map((formation) => ({
                value: formation.id,
                label: `${capitalizeFirstLetter(formation?.name)}`,
                category: formation.categorie?.name,
              })),
            ]}
            onChange={(selectedOption) =>
              handleSearchformationChange(selectedOption)
            }
            onInputChange={(inputValue) => setSearchTerm(inputValue)}
            inputValue={searchTerm}
            placeholder="Par Formation"
            getOptionLabel={(option) => (
              <div>
                <span style={{ fontSize: "1em" }}>
                  {capitalizeFirstLetter(option.label)}
                </span>
                {option.category && (
                  <span
                    style={{
                      fontSize: "0.8em",
                      color: "gray",
                      marginLeft: "5px",
                      fontStyle: "italic",
                    }}
                  >
                    {capitalizeFirstLetter(option.category)}
                  </span>
                )}
              </div>
            )}
            filterOption={(candidate, input) =>
              candidate.data.label.toLowerCase().includes(input.toLowerCase())
            }
            styles={{
              control: (base) => ({
                ...base,
                width: "250px",
                height: "5px",
              }),
            }}
          />
        </Col>

        <Col xs={12} md={3} className=" mb-md-0 d-flex justify-content-center">
          <Form.Select
            aria-label="Select Méthode d'enseignement"
            onChange={handleSearchmethodeChange}
            value={selectedTeachingMethod}
          >
            <option value="all">Toutes les méthodes d'enseignement</option>
            <option value="At home">À Domicile</option>
            <option value="Group">Groupe</option>
            <option value="Individual">Individuel</option>
          </Form.Select>
        </Col>
      </Row>

      <div className="table-responsive-etud">
        <Table bordered hover size="lg" responsive>
          <thead>
            <tr>
              <th>N</th>
              <th>Formation</th>
              <th>Niveau</th>
              <th>Méthode d'enseignement</th>
              <th>Durée de formation</th>
              <th>Nbr séance par Semaine</th>
              <th>Durée séance</th>
              <th>Prix</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {status === "loading" ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <Spinner animation="border" role="status" />
                  <span className="ms-2">Chargement...</span>
                </td>
              </tr>
            ) : teachingChoices && teachingChoices.length > 0 ? (
              teachingChoices.map((choice, index) => (
                <tr key={choice.id}>
                  <td>{index + 1}</td>
                  <td>{capitalizeFirstLetter(choice.formation?.name)}</td>
                  <td>
                    {choice.level?.name
                      ? choice.level?.name?.toUpperCase()
                      : "	Non existant"}
                  </td>
                  <td>
                    {choice.teaching_method === "At home"
                      ? "À Domicile"
                      : choice.teaching_method === "Group"
                      ? "Groupe"
                      : choice.teaching_method === "Individual"
                      ? "Individuel"
                      : choice.teaching_method}
                  </td>

                  <td>
                    {choice.formation_duration === 0
                      ? "Par mois"
                      : choice.formation_duration + " Mois"}
                  </td>

                  <td>{choice.session_nb}</td>
                  <td>
                    {choice.session_duration
                      ? `${choice.session_duration}H`
                      : ""}
                  </td>
                  <td>{choice.price ? `${choice.price}Dh` : ""} </td>
                  <td className="text-center">
                    <FaEdit
                      className="m-2 "
                      style={{ color: "green" }}
                      onClick={() => handleShowModal(choice)}
                    ></FaEdit>
                    <FaTrash
                      style={{ color: "red" }}
                      className="m-2"
                      onClick={() => handleDelete(choice.id)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  Aucun choix disponible
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination Controls */}
      <Row className="justify-content-center">
        <Col xs="auto">
          <Pagination>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode
              ? "Modifier le choix d'enseignement"
              : "Ajouter un choix d'enseignement"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Row className="mb-3">
              <Col
                md={levelsState && levelsState.length > 0 ? 6 : 12}
                className="animate__animated animate__fadeIn"
              >
                <Form.Group>
                  <Form.Label>Formation</Form.Label>
                  <Select
                    options={formationsWithCategories?.map((f) => ({
                      value: f.id,
                      label: `${capitalizeFirstLetter(f.name)}`,
                      category: f.categorie?.name,
                    }))}
                    name="formation_id"
                    onChange={(selectedOption) => {
                      handleInputChange({
                        target: {
                          name: "formation_id",
                          value: selectedOption.value,
                        },
                      });
                    }}
                    onInputChange={(inputValue) => setSearchTerm(inputValue)}
                    inputValue={searchTerm}
                    placeholder="Par Formation"
                    getOptionLabel={(option) => (
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          {capitalizeFirstLetter(option.label)}
                        </span>
                        {option.category && (
                          <span
                            style={{
                              fontSize: "0.8em",
                              color: "gray",
                              marginLeft: "5px",
                              fontStyle: "italic",
                            }}
                          >
                            {capitalizeFirstLetter(option.category)}
                          </span>
                        )}
                      </div>
                    )}
                    filterOption={(candidate, input) =>
                      candidate.data.label
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                {levelsState && levelsState.length > 0 && (
                  <Form.Group>
                    <Form.Label>Niveau</Form.Label>
                    <Form.Select
                      aria-label="sélectionner le niveau"
                      name="level_id"
                      value={formData.level_id}
                      onChange={handleInputChange}
                    >
                      <option value="">Sélectionner niveau</option>
                      {levelsState.map((level) => (
                        <option key={level.id} value={level.id}>
                          {level?.name?.toUpperCase()}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Méthode d'enseignement</Form.Label>
                  <Form.Select
                    name="teaching_method"
                    value={formData.teaching_method}
                    onChange={handleInputChange}
                  >
                    <option>sélectionner Méthode</option>
                    <option value="At home" selected>
                      À Domicile
                    </option>
                    <option value="Group">Groupe</option>
                    <option value="Individual">Individuel</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Durée de séance par Heure</Form.Label>
                  <Form.Select
                    name="session_duration"
                    value={formData.session_duration}
                    onChange={handleInputChange}
                  >
                    <option value="">Sélectionner une Durée</option>
                    {generateTimeOptions().map((time, index) => (
                      <option key={index} value={time}>
                        {time}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Nombre de séance par Semaine </Form.Label>
                  <Form.Control
                    type="number"
                    name="session_nb"
                    value={formData.session_nb}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group>
                  <Form.Label>Prix</Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={12} className="FormationD">
                <div className="titre">Durée de la formation</div>
                <div className="group_radio">
                  <div className="radios">
                    <div className="radio_label">
                      <label>Mois</label>
                      <input
                        type="radio"
                        name="durationType"
                        value="mois"
                        checked={formData.durationType === "mois"}
                        onChange={() => {
                          setFormData({
                            ...formData,
                            durationType: "mois",
                            formation_duration: 0,
                          });
                        }}
                      />
                    </div>
                    <div className="radio_label">
                      <label>Pack</label>
                      <input
                        type="radio"
                        name="durationType"
                        value="number"
                        checked={formData.durationType === "number"}
                        onChange={() => {
                          setFormData({
                            ...formData,
                            durationType: "number",
                            formation_duration: "",
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                {formData.durationType === "number" && (
                  <Form.Control
                    type="number"
                    placeholder="Ecrire le nombre de mois..."
                    name="formation_duration"
                    value={formData.formation_duration}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        formation_duration: e.target.value,
                      });
                    }}
                  />
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              type="submit"
              className="mr-auto"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" />
                  {editMode ? "Modifierement..." : "Enregistrement..."}
                </>
              ) : editMode ? (
                "Modifier"
              ) : (
                "Enregistrer"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmtion suppresion </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer ce choix d'enseignement ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmDelete(false)}
          >
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
