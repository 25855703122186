import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

export const fetchSchoolYears = createAsyncThunk(
  "schoolYears/fetchSchoolYears",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get("/school-years");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSchoolYear = createAsyncThunk(
  "schoolYears/addSchoolYear",
  async (newYear, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post("/school-years", newYear);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSchoolYear = createAsyncThunk(
  "schoolYears/deleteSchoolYear",
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/school-years/${id}`);
      return id; // Return the id to remove it from the state
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const schoolYearsSlice = createSlice({
  name: "schoolYears",
  initialState: {
    schoolYears: [],
    selectedYear: "",
    status: "idle",
    error: null,
  },
  reducers: {
    setSelectedYear(state, action) {
      state.selectedYear = action.payload;
      localStorage.setItem("lastSelectedYear", action.payload);
    },
    clearError(state) {
      state.error = null;
    },
    clearStore(state) {
      state.schoolYears = [];
      state.selectedYear = "";
      state.status = "idle";
      state.error = null;
      localStorage.removeItem("lastSelectedYear");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchoolYears.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSchoolYears.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.schoolYears = action.payload;
        const lastYear = localStorage.getItem("lastSelectedYear");
        if (lastYear && action.payload.find((year) => year.id === lastYear)) {
          state.selectedYear = lastYear;
        } else if (action.payload.length > 0) {
          state.selectedYear = action.payload[0].id;
        }
      })
      .addCase(fetchSchoolYears.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addSchoolYear.fulfilled, (state, action) => {
        // state.schoolYears.push(action.payload);
        state.schoolYears = [action.payload, ...state.schoolYears];
        state.selectedYear = action.payload.id;
        localStorage.setItem("lastSelectedYear", action.payload.id);
      })
      .addCase(addSchoolYear.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteSchoolYear.fulfilled, (state, action) => {
        state.schoolYears = state.schoolYears.filter(
          (year) => year.id !== action.payload
        );
        if (state.selectedYear === action.payload) {
          if (state.schoolYears.length > 0) {
            state.selectedYear = state.schoolYears[0].id;
            localStorage.setItem("lastSelectedYear", state.schoolYears[0].id);
          } else {
            state.selectedYear = ""; // إذا كانت القائمة فارغة، لا يوجد سنة محددة
            localStorage.removeItem("lastSelectedYear");
          }
        }
      })
      .addCase(deleteSchoolYear.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { setSelectedYear, clearError , clearStore } = schoolYearsSlice.actions;

export default schoolYearsSlice.reducer;
