import { Modal, Button, Table } from "react-bootstrap";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { updateTeachingSession } from "../../Redux/teacheingSession/teachingSessionSlice";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const EventDetailsIndividual = ({
  show,
  DetailsSession,
  handleDeleteRepeating,
  handleDeleteSession,
  setEventDetailsModalIndividual,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isStudentAbsent, setIsStudentAbsent] = useState(DetailsSession.isAbsent === "Student");
  const [isTeacherAbsent, setIsTeacherAbsent] = useState(DetailsSession.isAbsent === "Teacher");
  const [done, setDone] = useState(DetailsSession.done || false);

  const handleTeacherCheckboxChange = () => {
    setIsTeacherAbsent((prev) => !prev);
    if (!isTeacherAbsent) {
      setIsStudentAbsent(false);
    }
  };



  const handleStudentCheckboxChange = () => {
    setIsStudentAbsent((prev) => !prev);
    if (!isStudentAbsent) {
      setIsTeacherAbsent(false);
    }
  };

  const handleDoneClick = async () => {
    const updatedSessionData = {
      ...(isStudentAbsent && { isAbsent: "Student" }),
      ...(isTeacherAbsent && { isAbsent: "Teacher" }),
      ...(done && { done }),
    };


    try {
      await dispatch(
        updateTeachingSession({
          id: DetailsSession.id,
          sessionData: updatedSessionData,
        })
      ).unwrap();
      toast.success("Mise à jour réussie !");
    } catch (error) {
      const status = error?.status || error?.response?.status;
      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message);
          break;
      }
    }

    handleClose();
  };

  const [showTextarea, setShowTextarea] = useState(false);
  const [description, setDescription] = useState(
    DetailsSession.description || ""
  );


  const handleClose = () =>{
    setEventDetailsModalIndividual(false);
    setDone(false);
    setIsStudentAbsent('');
    setIsTeacherAbsent('');
  }


  useEffect(() => {
    if (DetailsSession) {
      setDescription(DetailsSession.description || "");
    }
  }, [DetailsSession]);

  useEffect(() => {
    if (DetailsSession) {
      setIsStudentAbsent(DetailsSession.isAbsent === "Student");
      setIsTeacherAbsent(DetailsSession.isAbsent === "Teacher");
      setDone(DetailsSession.done);
    }
  }, [DetailsSession]);

  const toggleTextarea = async () => {
    setShowTextarea((prev) => !prev);
    try {
      if (showTextarea) {
       await dispatch(
          updateTeachingSession({
            id: DetailsSession.id,
            sessionData: { description : description?.toLowerCase() },
          })
        ).unwrap();
        toast.success("Mise à jour réussie !");
      }
    } catch (error) {
      const status = error?.status || error?.response?.status;
      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message);
          break;
      }
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Détails de la Séance </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="text-center mb-2" style={{background:"#0d6efd", color:"white" , borderRadius:"5px" , padding:"2px"}}>Details Séance</h5>
        <div style={{height:"25vh" , overflowY:"hidden" }}>
        <Table bordered responsive>
          <thead>
            <tr className="text-center align-middle">
              <th>Jour</th>
              <th>Heure de début</th>
              <th>Heure de fin</th>
              <th>Prof</th>
              <th>
                {isTeacherAbsent ? (
                  <span style={{ color: "red" }}>Absence</span>
                ) : (
                  "Présent"
                )}
                Prof
              </th>
              <th>
                {isStudentAbsent ? (
                  <span style={{ color: "red" }}>Absence</span>
                ) : (
                  "Présent"
                )}
                Étudiant
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center align-middle">
              <td>{moment(DetailsSession.start).format("YYYY-MM-DD")}</td>
              <td>{moment(DetailsSession.start).format("HH:mm")}</td>
              <td>{moment(DetailsSession.end).format("HH:mm")}</td>
              <td>
                {capitalizeFirstLetter(DetailsSession.teacher?.nom) +" " +capitalizeFirstLetter(DetailsSession.teacher?.prenom)}
              </td>
              <td className="text-center">
                <input
                  type="checkbox"
                  checked={isTeacherAbsent}
                  onChange={handleTeacherCheckboxChange}
                />
              </td>
              <td className="text-center">
                <input
                  type="checkbox"
                  checked={isStudentAbsent}
                  onChange={handleStudentCheckboxChange}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        </div>

        <div>
          <div className="d-flex justify-content-center align-items-center">
            <h5>Description</h5>
            <FaPlus
              className="fa-plus"
              style={{ marginBottom: "5px", color: "blue", size: "20px" }}
              onClick={toggleTextarea}
            />
          </div>
          {showTextarea && (
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4"
              className="form-control"
            />
          )}
          {!showTextarea && description && (
            <p className="text-muted mt-2">{description}</p>
          )}
          {showTextarea && !description && (
            <p className="text-muted">Aucune description fournie.</p>
          )}
        </div>

        <div className="d-flex justify-content-end">
          <label className="checkindividual">
            <input
              type="checkbox"
              checked={done}
              onChange={() => setDone((prev) => !prev)}
            />
            <span className="checkmark"></span>
            <span style={{ fontSize: "15px", color: "green" }}>
              Est-ce que tout va bien dans cette séance ? oui{" "}
            </span>
          </label>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <div>
            {DetailsSession.repeating_id !== null && (
              <Button
                variant="danger"
                className="m-2"
                size="sm"
                onClick={handleDeleteRepeating}
              >
                Supprimer séries
              </Button>
            )}
            <Button variant="danger" size="sm" onClick={handleDeleteSession}  className="m-2">
              Supprimer ceci
            </Button>
          </div>
          <div>
            <Button variant="primary" size="sm" onClick={handleDoneClick}  className="m-2">
              Enregistrer
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EventDetailsIndividual;
