import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from '../../api/axios';


export const addTeachingSession = createAsyncThunk(
  'teachingSessions/addTeachingSession',
  async (sessionData, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post('/teaching-sessions', sessionData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



let abortControllerTeaching = new AbortController(); 

export const fetchTeachingSessions = createAsyncThunk(
  'teachingSessions/fetchTeachingSessions',
  async ({ teachingId, startDate, endDate }) => {
      if (abortControllerTeaching.signal) {
        abortControllerTeaching.abort();
      }
      abortControllerTeaching = new AbortController();
      const response = await axiosPrivate.get(`/teaching-sessions/${teachingId}`, {
        params: { start_date: startDate, end_date: endDate },
        signal: abortControllerTeaching.signal, 
      });
      return response.data;
    }
);


export const fetchOneTeachingSession = createAsyncThunk(
  'teachingSessions/fetchOneTeachingSession',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/teaching-session/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



export const updateTeachingSession = createAsyncThunk(
  'teachingSessions/updateTeachingSession',
  async ({ id, sessionData }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`/teaching-sessions/${id}`, sessionData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const deleteTeachingSession = createAsyncThunk(
  'teachingSessions/deleteTeachingSession',
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/teaching-sessions/${id}`);
      return id; 
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const teachingSessionsSlice = createSlice({
  name: 'teachingSessions',
  initialState: {
    sessions: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTeachingSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(addTeachingSession.fulfilled, (state, action) => {
        state.loading = false;
        state.sessions.push(action.payload);
      })
      .addCase(addTeachingSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchTeachingSessions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTeachingSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.sessions = action.payload;
      })
      .addCase(fetchTeachingSessions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateTeachingSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTeachingSession.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.sessions.findIndex(session => session.id === action.payload.id);
        if (index !== -1) {
          state.sessions[index] = action.payload; 
        }
      })
      .addCase(updateTeachingSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })
      .addCase(deleteTeachingSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTeachingSession.fulfilled, (state, action) => {
        state.loading = false;
        state.sessions = state.sessions.filter(session => session.id !== action.payload);
      })
      .addCase(deleteTeachingSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })
      .addCase(fetchOneTeachingSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOneTeachingSession.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.sessions.findIndex(session => session.id === action.payload.id);
        if (index !== -1) {
          state.sessions[index] = action.payload; 
        } else {
          state.sessions.push(action.payload); 
        }
      })
      .addCase(fetchOneTeachingSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      });
  },
});

export default teachingSessionsSlice.reducer;