
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPassword,
  formResetPassword,
} from "../../Redux/Auth/resetPasswordSlice";
import { useNavigate, useParams } from "react-router-dom";
import TopNavbar from "../../pages/Site/components/Nav/TopNavbar";
import { toast } from 'react-toastify';
import './ResetPassword.css';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => state.resetPassword
  );
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");

  useEffect(() => {
    dispatch(formResetPassword(token));
  }, [dispatch, token]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmationPassword) {
      toast.error("Les mots de passe ne correspondent pas");
      return;
    }

    try {
      await dispatch(
        resetPassword({
          token,
          password,
          confirmation_password: confirmationPassword,
        })
      ).unwrap();
      toast.success("Réinitialisation du mot de passe réussie !");
      navigate("/login");
    } catch (err) {
      toast.error(err.message || "An error occurred");
    }
  };

  return (
    <div>
      <TopNavbar />
      <div className="container-form">
        <div className="groupe-form">
        <h2>Réinitialiser le mot de passe</h2>
        <form className="forms">
          <input
            type="password"
            placeholder="Nouveau mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirmer le mot de passe"
            value={confirmationPassword}
            onChange={(e) => setConfirmationPassword(e.target.value)}
          />
          <button onClick={handleResetPassword} disabled={loading}>
            {loading ? "Réinitialisation en cours..." : "Réinitialiser le mot de passe"}
          </button>
        </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
