import React from "react";
import styled from "styled-components";
import TopNavbar from "./components/Nav/TopNavbar";
import Header from "./components/Sections/Header";
import Services from "./components/Sections/Services";
import Blog from "./components/Sections/Blog";
import Footer from "./components/Sections/Footer";
import Pricing from "./components/Sections/Pricing";

import whatssap from "./assets/img/contact/whatssap.png";
import linkdein from "./assets/img/contact/linkdein.png";
import gmail from "./assets/img/contact/gmail.png";
import instagram from "./assets/img/contact/instagram.png";
import facebook from "./assets/img/contact/facebook.png";

// Styles pour le bouton flottant
const FloatingButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  z-index: 4000;

  &:hover {
    background-color: #0056b3;
  }
`;

// Styles pour le conteneur des icônes
const IconsContainer = styled.div`
  position: fixed;
  bottom: 100px;
  right:10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  animation: fadeIn 0.6s;
  z-index: 4000;

  & > div {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

// Styles pour l'icône
const Icon = styled.img`
  width: 80px;
  border-radius: 8px;
  // background-color: #28a745;
`;

export const Site = () => {
  const [showIcons, setShowIcons] = React.useState(false);
  const [isMessageIcon, setIsMessageIcon] = React.useState(true);

  const toggleIcons = () => {
    setShowIcons(!showIcons);
    setIsMessageIcon(!isMessageIcon);
  };

  // Définir les icônes et les liens
  const socialLinks = [
    { src: whatssap, alt: "Icon 1", to: "https://wa.me/0604666307" },
    { src: gmail, alt: "Icon 2", to: "mailto:gycole.contact@gmail.com" },
    { src: linkdein, alt: "Icon 2", to: "https://www.linkedin.com/in/oussa-abdessamad-1460a3336/" },
    { src: instagram, alt: "Icon 2", to: "https://www.instagram.com/gycole_maroc/" },
    { src: facebook, alt: "Icon 2", to: "https://www.facebook.com/profile.php?id=61567472801598" },
  ];

  return (
    <div>
      <TopNavbar />
      <Header />
      <Services />
      <Pricing />
      <Blog />
      <Footer />
      <FloatingButton onClick={toggleIcons}>
      <i className={isMessageIcon ? "bx bx-message-square-detail" : "bx bx-x"}></i>
      </FloatingButton>
      {showIcons && (
        <IconsContainer>
          {socialLinks.map((icon, index) => (
            <div key={index}>
            <a href={icon.to} target="_blank" rel="noopener noreferrer">
              <Icon src={icon.src} alt={icon.alt} />
            </a>
          </div>
          ))}
        </IconsContainer>
      )}
    </div>
  );
};
