import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

export const fetchArchivedStudents = createAsyncThunk(
  "archivedStudents/fetchArchivedStudents",
  async ({ yearId, page = 1, pageSize = 10, searchTerm }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `/students-archives/${yearId}`,
        {
          params: { page, pageSize, searchTerm },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteArchivedStudent = createAsyncThunk(
  "archivedStudents/deleteArchivedStudent",
  async (studentId, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/students-archives/${studentId}`);
      return studentId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const archivedStudentsSlice = createSlice({
  name: "archivedStudents",
  initialState: {
    archivedStudents: [],
    loading: false,
    error: null,
    totalStudents: 0,
    currentPage: 1,
    pageSize: 10,
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchArchivedStudents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchArchivedStudents.fulfilled, (state, action) => {
        state.loading = false;
        state.archivedStudents = action.payload.students;
        state.totalStudents = action.payload.totalStudents;
      })
      .addCase(fetchArchivedStudents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteArchivedStudent.fulfilled, (state, action) => {
        state.archivedStudents = state.archivedStudents.filter(
          (student) => student.id !== action.payload
        );
      })
      .addCase(deleteArchivedStudent.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { clearError, setCurrentPage } = archivedStudentsSlice.actions;

export default archivedStudentsSlice.reducer;
