import React from "react";
import styled from "styled-components";

// Assets
import LogoIcon from "../../assets/svg/Logo.png";
import { Link } from "react-scroll";
import { Link as RouteriLnk } from "react-router-dom";

export default function Footer() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBgs">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            {/* Logo Section */}
            <LogoSection>
              <img src={LogoIcon} alt="Logo" style={{ width: "70px" }} />
            </LogoSection>

            {/* Navigation Links Section */}
            <NavSection>
              <Link
                to="accueil"
                smooth={true}
                duration={200}
                className="navLink"
              >
                Accueil
              </Link>
              <Link
                to="services"
                smooth={true}
                duration={200}
                className="navLink"
              >
                Services
              </Link>
              <Link
                to="tarifs"
                smooth={true}
                duration={200}
                className="navLink"
              >
                Tarifs
              </Link>
              <Link to="blog" smooth={true} duration={200} className="navLink">
                Blog
              </Link>
            </NavSection>

            {/* Social Media Links Section */}
            <SocialSection>
              <a
                href="https://www.facebook.com/profile.php?id=61567472801598"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <i
                  className="bx bxl-facebook"
                  style={{ fontSize: "24px", margin: "0 10px" }}
                ></i>
              </a>
              <a
                href="https://www.instagram.com/gycole_maroc/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <i
                  className="bx bxl-instagram"
                  style={{ fontSize: "24px", margin: "0 10px" }}
                ></i>
              </a>
              <a
                href="https://www.linkedin.com/in/oussa-abdessamad-1460a3336/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <i
                  className="bx bxl-linkedin"
                  style={{ fontSize: "24px", margin: "0 10px" }}
                ></i>
              </a>
              <a
                href="mailto:gycole.contact@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Email"
              >
                <i
                  className="bx bx-envelope"
                  style={{ fontSize: "24px", margin: "0 10px" }}
                ></i>
              </a>
              <a
                href="https://wa.me/0604666307" 
                target="_blank"
                rel="noopener noreferrer"
                aria-label="WhatsApp"
              >
                <i
                  className="bx bxl-whatsapp"
                  style={{ fontSize: "24px", margin: "0 10px" }}
                ></i>
              </a>
            </SocialSection>
          </InnerWrapper>
          <StyleP className="whiteColor font13">
            © {getCurrentYear()} -{" "}
            <RouteriLnk to="/" className="purpleColor font13">
              Gycole{" "}
            </RouteriLnk>{" "}
            All Right Reserved
          </StyleP>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const LogoSection = styled.div`
  flex: 1;
  margin-left:20px;
`;

const NavSection = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;

  .navLink {
    margin: 0 15px;
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
  @media (max-width: 550px) {
    margin-top: 30px;
  }
`;

const SocialSection = styled.div`
  flex: 1;
  text-align: right;
  margin-right:10px;

  a {
    color: white;
    &:hover {
      color: #ffcc00; /* Change this to your desired hover color */
    }
  }
  @media (max-width: 550px) {
    margin-top: 30px;
  }
`;

const StyleP = styled.p`
  text-align: center;
  padding-bottom: 20px;
  @media (max-width: 550px) {
    padding-bottom: 20px;
  }
`;
