import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../Redux/Auth/authSlice";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import { toast } from "react-toastify";
import TopNavbar from "../../pages/Site/components/Nav/TopNavbar";
import { Helmet } from "react-helmet";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [localLoading, setLocalLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  const validateForm = () => {
    let formErrors = {};
    if (!formData.email) {
      formErrors.email = "Email est requise";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "L'email est invalide.";
    }
    if (!formData.password) {
      formErrors.password = "mot de passe est requise";
    } else if (formData.password.length < 6) {
      formErrors.password =
        "Le mot de passe doit comporter au moins 6 caractères.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await dispatch(login(formData)).unwrap();
        setLocalLoading(true);
        setTimeout(() => {
          navigate("/dashboard/home");
        }, 1000);
      } catch (error) {
        const status = error?.status || error?.response?.status;
        switch (status) {
          case 429:
            setShowForm(false);
            toast.error(
              "Vous avez fait trop d'erreurs. Veuillez réessayer plus tard."
            );
            break;
          case 403:
            navigate("/Forbidden");
            break;
          case 400:
            toast.error(
              "mote de passe ou email incorrect . contacter le support !"
            );
            break;
          case 404:
            toast.error("L'utilisateur est introuvable ! ");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Connexion à Gycole - Accédez à Votre Tableau de Bord</title>
        <meta
          name="description"
          content="Plateforme éducative pour la gestion des étudiants, des professeurs, des paiements et plus encore. Facilitez l'administration scolaire avec des outils modernes."
        />
        <meta
          name="keywords"
          content="éducation, gestion scolaire, étudiants, professeurs, paiements, plateforme éducative , login gycole , gycole "
        />
        <meta name="author" content="GyCole" />
        <meta property="og:title" content="Connexion à Gycole - Accédez à Votre Tableau de Bord" />
        <meta
          property="og:description"
          content="Gestion simple et efficace de vos sessions de soutien scolaire, suivi personnalisé et organisation optimisée."
        />
        <meta property="og:url" content="https://gycole.com/login" />
      </Helmet>
      <TopNavbar />
      <div className="modalformlogin">
        {showForm ? (
          <div className="form-wizard">
            <h1>Connexion</h1>
            <form onSubmit={handleSubmit}>
              <div className="steps-container">
                <div className="step current">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}

                  <label htmlFor="password">Mot de passe</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <span className="error">{errors.password}</span>
                  )}
                </div>
              </div>
              {errors.general && (
                <span className="error">{errors.general}</span>
              )}
              <div className="controls">
                <button type="submit" disabled={loading || localLoading}>
                  {localLoading ? "Chargement..." : "Connexion"}
                </button>
              </div>
              <div className="link-login">
                <Link to="/request-reset-password">
                  <p className="button-login">
                    Réinitialiser le mot de passe ?
                  </p>
                </Link>
                <Link to="/register">
                  <span
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    Créer un nouveau compte?
                  </span>
                </Link>
              </div>
            </form>
          </div>
        ) : (
          <div className="error-message">
            <h2>
              Vous avez fait trop d'erreurs. Veuillez réessayer plus tard.
            </h2>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
