import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

// Initial state
const initialState = {
  levels: [],
  status: "idle",
  error: null,
};

// Async thunks
export const fetchLevels = createAsyncThunk(
  "levels/fetchLevels",
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `/levels-by-categorie/${categoryId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error ? error : "Network error or server did not respond"
      );
    }
  }
);

// fetch level by formation
export const fetchLevelsByFormation = createAsyncThunk(
  "levels/fetchLevelsByFormation",
  async (formationId, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `/levels-by-formation/${formationId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error ? error : "Network error or server did not respond"
      );
    }
  }
);

export const addLevel = createAsyncThunk(
  "levels/addLevel",
  async (level, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post("/levels", level);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error ? error : "Network error or server did not respond"
      );
    }
  }
);

export const updateLevel = createAsyncThunk(
  "levels/updateLevel",
  async ({ id, name, category_id }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`/levels/${id}`, {
        name,
        category_id,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error ? error : "Network error or server did not respond"
      );
    }
  }
);

export const deleteLevel = createAsyncThunk(
  "levels/deleteLevel",
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/levels/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(
        error ? error : "Network error or server did not respond"
      );
    }
  }
);

// Slice
const levelsSlice = createSlice({
  name: "levels",
  initialState,
  reducers: {
    resetLevels: (state) => {
      state.levels = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLevels.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLevels.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.levels = action.payload;
      })
      .addCase(fetchLevels.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLevelsByFormation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLevelsByFormation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.levels = action.payload;
      })
      .addCase(fetchLevelsByFormation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addLevel.fulfilled, (state, action) => {
        state.levels.push(action.payload);
      })
      .addCase(updateLevel.fulfilled, (state, action) => {
        const index = state.levels.findIndex(
          (level) => level.id === action.payload.id
        );
        if (index !== -1) {
          state.levels[index] = action.payload;
        }
      })
      .addCase(deleteLevel.fulfilled, (state, action) => {
        state.levels = state.levels.filter(
          (level) => level.id !== action.payload
        );
      });
  },
});

export const { resetLevels } = levelsSlice.actions;
export default levelsSlice.reducer;
