import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

export const fetchProfessors = createAsyncThunk(
  "professors/fetchProfessors",
  async ({ page = 1, pageSize = 10, searchTerm }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get("/teachers", {
        params: { page, pageSize, searchTerm },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const addProfessor = createAsyncThunk(
  "professors/addProfessor",
  async (newProfessor, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post("/teachers", newProfessor);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const updateProfessor = createAsyncThunk(
  "professors/updateProfessor",
  async ({ id, updatedProfessor }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`/teachers/${id}`, updatedProfessor);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const deleteProfessor = createAsyncThunk(
  "professors/deleteProfessor",
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/teachers/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

const professorsSlice = createSlice({
  name: "professors",
  initialState: {
    professors: [],
    totalTeachers: 0,
    currentPage: 1,
    pageSize: 10,
    status: "idle",
    error: null,
  },
  reducers: {
    clearProfessors: (state) => {
      state.professors = [];
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfessors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProfessors.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.professors = action.payload.teachers;
        state.totalTeachers = action.payload.totalTeachers;
      })
      .addCase(fetchProfessors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload; 
      })
      .addCase(addProfessor.fulfilled, (state, action) => {
        state.professors.push(action.payload);
      })
      .addCase(addProfessor.rejected, (state, action) => {
        state.error = action.payload; 
      })
      .addCase(updateProfessor.fulfilled, (state, action) => {
        const index = state.professors.findIndex(
          (prof) => prof.id === action.payload.id
        );
        if (index !== -1) {
          state.professors[index] = action.payload;
        }
      })
      .addCase(updateProfessor.rejected, (state, action) => {
        state.error = action.payload; 
      })
      .addCase(deleteProfessor.fulfilled, (state, action) => {
        state.professors = state.professors.filter(
          (prof) => prof.id !== action.payload
        );
      })
      .addCase(deleteProfessor.rejected, (state, action) => {
        state.error = action.payload; 
      });
  },
});

export const { clearProfessors, setCurrentPage } = professorsSlice.actions;

export default professorsSlice.reducer;
