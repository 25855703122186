import React from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

const RepeatSessionModal = ({
  showSecondModal,
  handleSecondClose,
  startDate,
  setStartDate,
  intervalValue,
  setIntervalValue,
  repeatOption,
  selectedDays,
  handleDayClick,
  endDate,
  setEndDate,
  handleSaveRepetition,
  setRepeatOption,
  setIsRemote,
  skip,
  setSkip,
  handleRepeatOptionChange,
}) => {
  return (
    <Modal
      show={showSecondModal}
      onHide={handleSecondClose}
      size="lg"
      className="modal-dark"
    >
      <Modal.Header closeButton>
        <Modal.Title>Configurer Répétition</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-4 align-items-center">
            <Col md={4}>
              <Form.Group className="d-flex align-items-center">
                <Col md={9}>
                  <Form.Label className="mb-0 me-2 text-end">Début</Form.Label>
                </Col>
                <Form.Control
                  type="date"
                  value={startDate || ""}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4 align-items-center">
            <Col md={4}>
              <Form.Group
                controlId="formIntervalValue"
                className="d-flex align-items-center"
              >
                <Col md={9}>
                  <Form.Label className="mb-0 me-5">
                    Répéter tous les
                  </Form.Label>
                </Col>
                <Form.Control
                  type="number"
                  min={1}
                  value={intervalValue}
                  onChange={(e) => setIntervalValue(e.target.value)}
                  className="me-2"
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group
                controlId="formInterval"
                className="d-flex align-items-center"
              >
                <Form.Select
                  value={repeatOption}
                  onChange={handleRepeatOptionChange}
                >
                  <option value="">Pas de répétition</option>
                  <option value="Daily">Par Jour</option>
                  <option value="Weekly">Par Semaine</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {repeatOption === "Weekly" && (
              <Col className="mb-3 d-flex">
                <div className="days_of_week">
                  {[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ].map((day, index) => (
                    <div
                      key={day}
                      className={`jour ${
                        selectedDays.includes(day) ? "active" : ""
                      }`}
                      onClick={() => handleDayClick(day)}
                    >
                      {["L", "M", "M", "J", "V", "S", "D"][index]}
                    </div>
                  ))}
                </div>
              </Col>
            )}
          </Row>

          <Row className="mb-4 align-items-center">
            <Col md={4}>
              <Form.Group className="d-flex align-items-center">
                <Col md={9}>
                  <Form.Label className="mb-0 me-2"> Fin</Form.Label>
                </Col>
                <Form.Control
                  type="date"
                  value={endDate || ""}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Col>
            <label className="checkbox-container">
              Évitez de créer une séance si la salle n'est pas disponible !
              <input
                className="custom-checkbox"
                type="checkbox"
                checked={skip}
                onChange={() => setSkip(!skip)}
              />
              <span className="checkmark"></span>
            </label>
          </Col>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleSecondClose}>
          Fermer
        </Button>
        <Button variant="primary" onClick={handleSaveRepetition}>
          Enregistrer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RepeatSessionModal;
