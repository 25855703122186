import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from '../../api/axios';

// Initial state
const initialState = {
  rooms: [],
  status: 'idle',
  error: null,
};

// Async thunks
export const fetchRooms = createAsyncThunk(
  'rooms/fetchRooms',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get('/rooms');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addRoom = createAsyncThunk(
  'rooms/addRoom',
  async (room, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post('/rooms', room);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRoom = createAsyncThunk(
  'rooms/updateRoom',
  async ({ id, name }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`/rooms/${id}`, { name });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRoom = createAsyncThunk(
  'rooms/deleteRoom',
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/rooms/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Slice
const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    clearStoreRooms: (state) => {
      state.rooms = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRooms.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRooms.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.rooms = action.payload;
      })
      .addCase(fetchRooms.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; 
      })
      .addCase(addRoom.fulfilled, (state, action) => {
        state.rooms = [action.payload, ...state.rooms];
      })
      .addCase(addRoom.rejected, (state, action) => {
        state.error = action.payload; 
      })
      .addCase(updateRoom.fulfilled, (state, action) => {
        const index = state.rooms.findIndex(room => room.id === action.payload.id);
        if (index !== -1) {
          state.rooms[index] = action.payload;
        }
      })
      .addCase(updateRoom.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteRoom.fulfilled, (state, action) => {
        state.rooms = state.rooms.filter(room => room.id !== action.payload);
      })
      .addCase(deleteRoom.rejected, (state, action) => {
        state.error = action.payload; 
      });
  },
});

export const { clearStoreRooms } = roomsSlice.actions;

export default roomsSlice.reducer;
