import React, { useEffect, useState } from "react";
import "./Internal500.css"; 
import { Link } from "react-router-dom";

const Internal500 = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className={`body500 ${loading ? "loading" : ""}`}>
        <h1 className="h1500">500</h1>
        <h2 className="h2500">
        Erreur inattendue <b>:(</b>
        </h2>
        <div className="gears">
          <div className="gear one">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div className="gear two">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div className="gear three">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
        <Link className="retry-button" to="/">Accueil</Link>
      </div>
    </>
  );
};

export default Internal500;
