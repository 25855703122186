import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addAccount,
  deleteAccount,
  fetchAccounts,
  updateAccount,
} from "../../Redux/Compte/accountsSlice";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./compte.css";
import { useNavigate } from "react-router-dom";

export const Compte = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accounts = useSelector((state) => state.accounts.accounts);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isLoading, SetisLaoding] = useState(false);

  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    phone: "",
    password: "",
    confirmation_password: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchAccounts()).unwrap();
      } catch (error) {}
    };

    fetchData();
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = async (isUpdate) => {
    const errors = {};
    if (!formData.nom && !isUpdate) errors.nom = "Nom est requise.";
    else if (/^\d/.test(formData.nom))
      errors.nom = "Nom ne doit pas commencer par un chiffre.";

    if (!formData.prenom && !isUpdate) errors.prenom = "Prenom est requise.";
    else if (/^\d/.test(formData.prenom))
      errors.prenom = "Prenom ne doit pas commencer par un chiffre.";

    if (!formData.email) errors.email = "Email est requise.";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      errors.email = "Invalid email format.";

    if (!formData.phone && !isUpdate) errors.phone = "Telephone est requise.";
    else if (!/^\d{10}$/.test(formData.phone))
      errors.phone =
        "Le numéro de téléphone doit contenir exactement 10 chiffres.";

    if (!isUpdate) {
      if (!formData.password) {
        errors.password = "Mote de passe est requise.";
      } else if (
        formData.password.length < 6 ||
        formData.password.length > 100
      ) {
        errors.password =
          "Le mot de passe doit comporter entre 6 et 100 caractères.";
      }
      if (!formData.confirmation_password) {
        errors.confirmation_password =
          "Confirmation Mote de passe est requise.";
      } else if (formData.confirmation_password !== formData.password) {
        errors.confirmation_password = "Les mot de passe doivent correspondre.";
      }
    } else {
      if (
        formData.password &&
        formData.confirmation_password &&
        formData.confirmation_password !== formData.password
      ) {
        errors.confirmation_password = "Les mot de passe doivent correspondre.";
      }
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert all form data to lowercase
    const dataToSend = {
      ...formData,
      email: formData.email?.toLowerCase(),
      nom: formData.nom?.toLowerCase(),
      prenom: formData.prenom?.toLowerCase(),
    };

    const errors = await validateForm(isUpdate);
    if (Object.keys(errors).length) {
      Object.values(errors).forEach((error) => toast.error(error));
      return;
    }

    try {
      SetisLaoding(true);
      if (isUpdate) {
        const updatedData = { ...dataToSend };
        if (!updatedData.password) delete updatedData.password;
        if (!updatedData.confirmation_password)
          delete updatedData.confirmation_password;

        await dispatch(
          updateAccount({
            accountId: selectedAccount.id,
            updates: { ...updatedData },
          })
        ).unwrap();
        await dispatch(fetchAccounts()).unwrap();
        toast.success("Compte mis à jour avec succès.");
      } else {
        await dispatch(addAccount(dataToSend)).unwrap();
        toast.success("Compte ajouté avec succès.");
        await dispatch(fetchAccounts()).unwrap();
      }

      setShowModal(false);
      setFormData({
        nom: "",
        prenom: "",
        email: "",
        phone: "",
        password: "",
        confirmation_password: "",
      });
    } catch (error) {
      if (error) {
        switch (error.status) {
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          case 400:
            toast.error("email deja exist !");
            break;
          default:
            toast.error(error.message);
            break;
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      SetisLaoding(false);
    }
  };

  const handleAddClick = () => {
    setFormData({
      nom: "",
      prenom: "",
      email: "",
      phone: "",
      password: "",
      confirmation_password: "",
    });
    setIsUpdate(false);
    setShowModal(true);
  };

  const handleEditClick = (account) => {
    setFormData({
      email: account.email || "",
      password: "",
      confirmation_password: "",
    });
    setSelectedAccount(account);
    setIsUpdate(true);
    setShowModal(true);
  };

  const handleDelete = async (accountId) => {
    try {
      await dispatch(deleteAccount(accountId)).unwrap();
      toast.success("Compte supprimé avec succès.");
    } catch (error) {
      toast.error(`Error: ${error.message || "Failed to delete the account."}`);
    } finally {
      setShowDeleteModal(false);
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  return (
    <Container>
      <h2 className="text-center">Les comptes des secrétaires</h2>
      <FaPlus
        className="m-2"
        style={{ color: "blue", cursor: "pointer" }}
        onClick={handleAddClick}
      >
        Ajouter
      </FaPlus>

      <Table bordered hover size="sm" responsive>
        <thead>
          <tr className="text-center">
            <th>N</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Téléphone</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {accounts.length > 0 ? (
            accounts.map((account, index) => (
              <tr key={account.id} className="text-center">
                <td>{index + 1}</td>
                <td>{capitalizeFirstLetter(account.profile?.nom)}</td>
                <td>{capitalizeFirstLetter(account.profile?.prenom)}</td>
                <td>{account.profile?.phone}</td>
                <td>{capitalizeFirstLetter(account.email)}</td>
                <td className="d-flex col-12 justify-content-center">
                  <FaTrash
                    className="m-2"
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => {
                      setAccountToDelete(account.id);
                      setShowDeleteModal(true);
                    }}
                  />
                  <FaEdit
                    className="m-2 d-flex align-items-center justify-content-center"
                    style={{ color: "green" }}
                    onClick={() => handleEditClick(account)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">
                Aucun compte trouvé
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Modal for adding or updating account */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal-dark"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isUpdate ? "Modifier un compte" : "Ajouter un compte"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {isUpdate ? (
              <>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Col>
                  <Form.Group controlId="formPassword">
                    <Form.Label>Mot de passe</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formConfirmationPassword">
                    <Form.Label>Confirmation du mot de passe</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmation_password"
                      value={formData.confirmation_password}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <Form.Group controlId="formNom">
                      <Form.Label>Nom</Form.Label>
                      <Form.Control
                        type="text"
                        name="nom"
                        value={formData.nom}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formPrenom">
                      <Form.Label>Prénom</Form.Label>
                      <Form.Control
                        type="text"
                        name="prenom"
                        value={formData.prenom}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formPhone">
                      <Form.Label>Téléphone</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formPassword">
                      <Form.Label>Mot de passe</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formConfirmationPassword">
                      <Form.Label>Confirmation du mot de passe</Form.Label>
                      <Form.Control
                        type="password"
                        name="confirmation_password"
                        value={formData.confirmation_password}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            <Button
              variant="primary"
              className="mt-2"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" />
                  {" Enregistrement..."}
                </>
              ) : isUpdate ? (
                "Modifier"
              ) : (
                "Enregistrer"
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal for deleting account */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        className="modal-dark"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Êtes-vous sûr de vouloir supprimer ce compte ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDelete(accountToDelete)}
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
