
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from "../../api/axios";


export const  fetchDashboardData = createAsyncThunk(
    'dashboard/fetchDashboardData',
    async (year_id) => {
        const response = await axiosPrivate.get(`/dashboard/${year_id}`);
        return response.data;
    }
);

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        studentsCount: 0,
        formationsCount: 0,
        teachersCount: 0,
        rooms: [],
        schoolYears: [],
        categories: [],
        loading: false,
        error: null,
    },
    reducers: {
        clearDashboard: (state) => {
            state.studentsCount = 0;
            state.formationsCount = 0;
            state.teachersCount = 0;
            state.rooms = [];
            state.schoolYears = [];
            state.categories = [];
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDashboardData.fulfilled, (state, action) => {
                state.loading = false;
                state.studentsCount = action.payload.studentsCount;
                state.formationsCount = action.payload.formationsCount;
                state.teachersCount = action.payload.teachersCount;
                state.rooms = action.payload.rooms;
                state.schoolYears = action.payload.schoolYears;
                state.categories = action.payload.categories;
            })
            .addCase(fetchDashboardData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
