import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from '../../api/axios';


// Initial state
const initialState = {
  categories: [],
  status: 'idle',
  error: null,
};

// Async thunks
export const fetchCategories = createAsyncThunk('categories/fetchCategories', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosPrivate.get('/categories');
    return response.data;
  } catch (error) {
    return rejectWithValue(error ? error : 'Network error or server did not respond');
  }
});

export const addCategory = createAsyncThunk('categories/addCategory', async (category, { rejectWithValue }) => {
  try {
    const response = await axiosPrivate.post('/categories', category);
    return response.data;
  } catch (error) {
    return rejectWithValue(error ? error : 'Network error or server did not respond');

  }
});

export const updateCategory = createAsyncThunk('categories/updateCategory', async ({ id, name }, { rejectWithValue }) => {
  try {
    const response = await axiosPrivate.put(`/categories/${id}`, { name });
    return response.data;
  } catch (error) {
    return rejectWithValue(error ? error : 'Network error or server did not respond');

  }
});

export const deleteCategory = createAsyncThunk('categories/deleteCategory', async (id, { rejectWithValue }) => {
  try {
    await axiosPrivate.delete(`/categories/${id}`);
    return id;
  } catch (error) {
    return rejectWithValue(error ? error : 'Network error or server did not respond');

  }
});
// Slice

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    clearStoreCategorie: (state) => {
      state.categories = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories.push(action.payload);
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.categories.findIndex(category => category.id === action.payload.id);
        if (index !== -1) {
          state.categories[index] = action.payload;
        }
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = state.categories.filter(category => category.id !== action.payload);
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;

      });
  },
});


export const { clearStoreCategorie } = categoriesSlice.actions;

export default categoriesSlice.reducer;



