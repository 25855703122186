import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestResetPassword } from "../../Redux/Auth/resetPasswordSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import TopNavbar from "../../pages/Site/components/Nav/TopNavbar";
import "./RequestPasswordReset.css";
import { useNavigate } from "react-router-dom";

const RequestPasswordReset = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => state.resetPassword
  );
  const [email, setEmail] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const [requestSent, setRequestSent] = useState(false);
  const navigate = useNavigate();

  const handleRequestReset = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      toast.error("Veuillez compléter le CAPTCHA.");
      return;
    }
    try {
     await dispatch(requestResetPassword(email)).unwrap();
      setRequestSent(true);
      toast.success("Lien de réinitialisation envoyé ! Vérifiez votre email.");
    }  catch (error) {

      const status = error?.status || error?.response?.status;
      switch (status) {
        case 429: 
          toast.error(
            "Vous avez fait trop d'erreurs. Veuillez réessayer plus tard."
          ); 
          break;
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          toast.error("L'utilisateur est introuvable ! ");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message);
          break;
      }
    }
  };

  return (
    <div>
      <TopNavbar />
      <div className="container-password">
        <div className="container-contenu">
          <div className="request-password">
            <h2>Demander la réinitialisation du mot de passe</h2>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={requestSent}
            />
          </div>
          <div className="btn-capatcha">
          <div className="recaptcha">
            <ReCAPTCHA
              sitekey="6Le_CogqAAAAAJuqgvvy6wlUYl9NAAv1IJNhkxCw"
              onChange={(value) => setCaptchaValue(value)}
            />
          </div>
          <div className="btn-contenu">
            <button
              onClick={handleRequestReset}
              disabled={loading || !captchaValue || requestSent}
            >
              {loading
                ? "En cours d'envoi..."
                : requestSent
                ? "Demande envoyée"
                : "Envoyer le lien de réinitialisation"}
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestPasswordReset;
