import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from '../../api/axios';

export const fetchTeachingStudents = createAsyncThunk(
  'teachingStudents/fetchTeachingStudents',
  async ({ page = 1, pageSize = 10 , formationId }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/teaching-students/${formationId}/formation`, {
        params: { page, pageSize },
      });
      return response.data; 
    } catch (error) {
      return rejectWithValue(error); 
    }
  }
);

export const addTeachingStudent = createAsyncThunk(
  'teachingStudents/addTeachingStudent',
  async (studentData, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post('/teaching-students', studentData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateTeachingStudent = createAsyncThunk(
  'teachingStudents/updateTeachingStudent',
  async ({ id, studentData }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`/teaching-students/${id}`, studentData);
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response || error.message); 
    }
  }
);

export const deleteTeachingStudent = createAsyncThunk(
  'teachingStudents/deleteTeachingStudent',
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/teaching-students/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchOneTeachingStudent = createAsyncThunk(
  'teachingStudents/fetchOneTeachingStudent',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/teaching-students/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const teachingStudentSlice = createSlice({
  name: 'teachingStudents',
  initialState: {
    teachingStudents: [],
    currentStudent: null,
    status: 'idle',
    error: null,
    totalTeachingStudents: 0, 
    currentPage: 1,
    pageSize: 10,
  },
  reducers: {
    resetCurrentStudent: (state) => {
      state.currentStudent = null;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeachingStudents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTeachingStudents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.teachingStudents = action.payload.teachingStudents;
        state.totalTeachingStudents = action.payload.totalTeachingStudents;
      })
      .addCase(fetchTeachingStudents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      
      .addCase(addTeachingStudent.fulfilled, (state, action) => {
        state.teachingStudents.push(action.payload); 
      })
      .addCase(addTeachingStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      
// In the extraReducers section of your Redux slice
.addCase(updateTeachingStudent.fulfilled, (state, action) => {
  const { id, studentData } = action.payload;  // Ensure 'studentData' matches the dispatched payload
  state.teachingStudents = state.teachingStudents.map(teachingStudent =>
    teachingStudent.id === id ? { ...teachingStudent, ...studentData } : teachingStudent
  );
})

      .addCase(updateTeachingStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;  
      })
      
      .addCase(deleteTeachingStudent.fulfilled, (state, action) => {
        state.teachingStudents = state.teachingStudents.filter(student => student.id !== action.payload);
        state.currentStudent = null; 
      })
      .addCase(deleteTeachingStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      .addCase(fetchOneTeachingStudent.fulfilled, (state, action) => {
        state.currentStudent = action.payload;
      })
      .addCase(fetchOneTeachingStudent.pending, (state) => {
        state.status = 'loading'; 
      })
      .addCase(fetchOneTeachingStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
        state.currentStudent = null; 
      });
  },
});

export const { resetCurrentStudent, setCurrentPage } = teachingStudentSlice.actions;

export default teachingStudentSlice.reducer;
