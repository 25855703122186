import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

const initialState = {
  accounts: [],
  status: "idle",
  error: null,
};

export const fetchAccounts = createAsyncThunk(
  "accounts/fetchAccounts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get("/accounts");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Failed to fetch accounts");
    }
  }
);

export const addAccount = createAsyncThunk(
  "accounts/addAccount",
  async (newAccount, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post("/accounts", newAccount);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Failed to add account");
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "accounts/deleteAccount",
  async (accountId, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/accounts/${accountId}`);
      return accountId;
    } catch (error) {
      return rejectWithValue(error.response.data || "Failed to delete account");
    }
  }
);

export const updateAccount = createAsyncThunk(
  "accounts/updateAccount",
  async ({ accountId, updates }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(
        `/accounts/${accountId}`,
        updates
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Failed to update account");
    }
  }
);

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    clearStoreAccount: (state) => {
        state.accounts = [];
        state.status = "idle";
        state.error = null;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.accounts = action.payload;
      })
      .addCase(fetchAccounts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addAccount.fulfilled, (state, action) => {
        // state.accounts = [action.payload, ...state.accounts];
        state.accounts.push(action.payload);
      })
      .addCase(addAccount.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.accounts = state.accounts.filter(
          (account) => account.id !== action.payload
        );
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        const index = state.accounts.findIndex(
          (account) => account.id === action.payload.id
        );
        if (index !== -1) {
          state.accounts[index] = action.payload;
        }
      })
      .addCase(updateAccount.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});


export const { clearStoreAccount } = accountsSlice.actions;
export default accountsSlice.reducer;
