import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "./TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="La plateforme de gestion de projets que vous proposez a fait une grande différence dans l'organisation de mon travail. Les outils disponibles facilitent le suivi des progrès et des tâches !"
            author="Said mansouri"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="J'adore l'interface utilisateur simple ! Je peux accéder rapidement et facilement à tout ce dont j'ai besoin, ce qui rend la gestion des projets plus efficace."
            author="yassmine zari"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Le support technique de votre équipe a été fantastique ! Ils étaient toujours disponibles pour m'aider lorsque j'en avais besoin, ce qui a amélioré mon expérience."
            author="kevin yago"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Je recommande vivement d'utiliser votre plateforme à tous ceux qui souhaitent mieux gérer leurs projets. C'est vraiment une expérience fantastique!"
            author="ismail elkhyati"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
