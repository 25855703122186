import React from "react";
import "./notFound.css";

export const NotFound = () => {
  return (
    <div className="general-not">
      <div className="mars"></div>
      <img
        src="https://assets.codepen.io/1538474/404.svg"
        alt="404 Logo"
        className="logo-404"
      />
      <img
        src="https://assets.codepen.io/1538474/meteor.svg"
        alt="Meteor"
        className="meteor"
      />
      <h1 className="titleoh">Oh no!!</h1>
      <p className="subtitle">
        Vous faites probablement une faute de frappe dans l'URL <br /> ou vous
        demandez une page qui n'est plus disponible.
      </p>
      <div align="center">
        <a className="btn-back" href="/">
        Accueil
        </a>
      </div>
      <img
        src="https://assets.codepen.io/1538474/astronaut.svg"
        alt="Astronaut"
        className="astronaut"
      />
      <img
        src="https://assets.codepen.io/1538474/spaceship.svg"
        alt="Spaceship"
        className="spaceship"
      />
    </div>
  );
};
