import React, { useState } from "react";
import "./Register.css";
import TopNavbar from "../../pages/Site/components/Nav/TopNavbar";
import { useDispatch } from "react-redux";
import { register } from "../../Redux/Auth/authSlice";
import whatssap from "../../assets/whatssap.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Register = () => {
  const [localLoading, setLocalLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    phone: "",
    email: "",
    password: "",
    name: "",
    address: "",
    confirmation_password: "",
  });
  const [errors, setErrors] = useState({});
  const [isRegistered, setIsRegistered] = useState(false);

  const validateStep = () => {
    let stepErrors = {};
    if (currentStep === 1) {
      if (!formData.name) {
        stepErrors.name = "Nom est requise";
      } else if (!/^[a-zA-Z ]+$/.test(formData.name)) {
        stepErrors.name =
          "Le nom ne doit contenir que des lettres et des espaces.";
      }
      if (!formData.address) stepErrors.address = "L'adresse est requise.";
    }

    if (currentStep === 2) {
      if (!formData.email) {
        stepErrors.email = "L'email est requise.";
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        stepErrors.email = "L'email est invalide.";
      }
      if (!formData.password) stepErrors.password = "Mot de passe est requise";
      if (formData.password !== formData.confirmation_password) {
        stepErrors.confirmation_password =
          "Les mots de passe ne correspondent pas.";
      }
      if (formData.password.length < 6) {
        stepErrors.password =
          "Le mot de passe doit comporter au moins 6 caractères";
      }
    }

    if (currentStep === 3) {
      if (!formData.nom) stepErrors.nom = "Nom est requise";
      else if (!/^[a-zA-Z ]+$/.test(formData.nom)) {
        stepErrors.nom = "Le nom ne doit contenir que des lettres et des espaces.";
      }
  
      if (!formData.prenom) stepErrors.prenom = "Prenom  est requise";
      else if (!/^[a-zA-Z ]+$/.test(formData.prenom)) {
        stepErrors.prenom = "Le prénom ne doit contenir que des lettres et des espaces.";
      }

      if (!formData.phone) {
        stepErrors.phone = "Le numéro de téléphone est requise.";
      } else if (!/^\d{10}$/.test(formData.phone)) {
        stepErrors.phone = "Le numéro de téléphone doit comporter 10 chiffres.";
      }
      
    }
    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep()) {
      setLocalLoading(true);

      const dataToSend = {
        ...formData,
        email: formData.email.toLowerCase(),
        nom: formData.nom.toLowerCase(),
        prenom: formData.prenom.toLowerCase(),
        name: formData.name.toLowerCase(),
        address: formData.address.toLowerCase(),
      };

      try {
        await dispatch(register(dataToSend)).unwrap();
        setIsRegistered(true);
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 401:
              setErrors({ general: "Invalid email ou mote de passe" });
              break;
            case 404:
              setErrors({
                general:
                  "Point de connexion introuvable. Veuillez contacter le support.",
              });
              break;
            case 500:
              setErrors({ general: "Server error, please try again later" });
              break;
            default:
              setErrors({ general: "email deja exist !" });
              break;
          }
        } else if (err.request) {
          setErrors({
            general: "Network error, please check your connection.",
          });
        } else {
          setErrors({
            general: "An error occurred while setting up the request.",
          });
        }
      } finally {
        setLocalLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Inscrivez-vous sur Gycole - Simplifiez la Gestion de Votre Centre Éducatif</title>
        <meta
          name="description"
          content="Plateforme éducative pour la gestion des étudiants, des professeurs, des paiements et plus encore. Facilitez l'administration scolaire avec des outils modernes."
        />
        <meta
          name="keywords"
          content="éducation, gestion scolaire, étudiants, professeurs, paiements, plateforme éducative , register gycole , gycole "
        />
        <meta name="author" content="GyCole" />
        <meta property="og:title" content="Inscrivez-vous sur Gycole - Simplifiez la Gestion de Votre Centre Éducatif" />
        <meta
          property="og:description"
          content="Gestion simple et efficace de vos sessions de soutien scolaire, suivi personnalisé et organisation optimisée."
        />
        <meta property="og:url" content="https://gycole.com/register" />
      </Helmet>

      <TopNavbar />
      <div className="modalform">
        <div className="form-wizard">
          <h1>Inscription</h1>
          <div className="progress-container">
            <ol>
              <li className={currentStep >= 1 ? "done" : ""}>Etape 1 </li>
              <li className={currentStep >= 2 ? "done" : ""}>Etape 2 </li>
              <li className={currentStep >= 3 ? "done" : ""}>Etape 3 </li>
            </ol>
            <div
              className="progress"
              style={{ transform: `scaleX(${(currentStep - 1) / 2})` }}
            ></div>
          </div>
          {!isRegistered ? (
            <form onSubmit={handleSubmit}>
              <div className="steps-container">
                {currentStep === 1 && (
                  <div className="step current">
                    <h3>Etape 1: Informations du centre</h3>
                    <label>Nom de centre</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                    <label>Adresse de center</label>
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                    {errors.address && (
                      <span className="error">{errors.address}</span>
                    )}
                  </div>
                )}
                {currentStep === 2 && (
                  <div className="step current">
                    <h3>Etape 2: Informations personnelles</h3>
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                    <label>Mot de passe</label>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <span className="error">{errors.password}</span>
                    )}
                    <label>Confirmer le mot de passe</label>
                    <input
                      type="password"
                      name="confirmation_password"
                      value={formData.confirmation_password}
                      onChange={handleChange}
                    />
                    {errors.confirmation_password && (
                      <span className="error">
                        {errors.confirmation_password}
                      </span>
                    )}
                  </div>
                )}
                {currentStep === 3 && (
                  <div className="step current">
                    <h3>Etape 3: Informations du profil</h3>
                    <label>Nom</label>
                    <input
                      type="text"
                      name="nom"
                      value={formData.nom}
                      onChange={handleChange}
                    />
                    {errors.nom && <span className="error">{errors.nom}</span>}
                    <label>Prénom</label>
                    <input
                      type="text"
                      name="prenom"
                      value={formData.prenom}
                      onChange={handleChange}
                    />
                    {errors.prenom && (
                      <span className="error">{errors.prenom}</span>
                    )}
                    <label>Telephone</label>
                    <input
                      type="number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && (
                      <span className="error">{errors.phone}</span>
                    )}
                  </div>
                )}
              </div>
              {errors.general && (
                <span className="error">{errors.general}</span>
              )}
              <div className="controls">
                {currentStep > 1 && (
                  <button type="button" onClick={handleBack}>
                    Retour
                  </button>
                )}
                {currentStep < 3 && (
                  <button type="button" onClick={handleNext}>
                    Suivant
                  </button>
                )}
                {currentStep === 3 && (
                  <button type="submit" disabled={localLoading}>
                    {localLoading ? "Loading..." : "Submit"}
                  </button>
                )}
              </div>
              <div className="m-2" style={{ fontSize: "13px" }}>
                Avez-vous un compte ?
                <Link to="/login" style={{ color: "blue" }}>
                  {" "}
                  Se connecter
                </Link>
              </div>
            </form>
          ) : (
            // إذا تم التسجيل بنجاح، عرض رسالة شكر
            <div className="thank-you-message text-center mt-5">
              <h2>Merci pour votre inscription !</h2>
              <p className="mt-4">
                Nous avons bien reçu votre demande. Nous vous contacterons dans
                les plus brefs délais.
              </p>
              <p>Ou contactez-nous via :</p>
              <a
                href="https://wa.me/0604666307"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={whatssap} alt="Contact us on WhatsApp" width="50px" />
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
