import React from "react";
import styled from "styled-components";
// Assets
import CalendarIcon from "../../assets/svg/Services/Calendar.png";
import Barchart from "../../assets/svg/Services/Bar-chart.png";
import prix from "../../assets/svg/Services/prix.png";
import dashboard from "../../assets/svg/Services/dashboard.png";

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "calendar":
      getIcon = <img src={CalendarIcon} alt="" width={120} />;
      break;
    case "bar-chart":
      getIcon = <img src={Barchart} alt="" width={120} />;
      break;
    case "prix":
      // getIcon = <BrowserIcon />;
      getIcon = <img src={prix} alt="" width={120} />;
      break;
    case "dashboard":
      getIcon = <img src={dashboard} alt="" width={120} />;
      break;
    default:
      getIcon = <img src={dashboard} alt="" width={120} />;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;