import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.jpg";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <Wrapper id="accueil" className="container-fluied flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">Bienvenue à Gycole.</h1>
          <HeaderP className="font16 semiBold">
            Chez Gycole, nous croyons fermement que chaque étudiant mérite un suivi
            pédagogique personnalisé et de qualité. Notre plateforme innovante
            est spécialement conçue pour simplifier la gestion des cours, des
            absences et des communications, afin de permettre aux enseignants et
            aux étudiants de se concentrer sur l'essentiel : l'apprentissage et la
            réussite académique. En choisissant GyCole, vous intégrez une
            communauté dynamique dédiée à favoriser le succès éducatif.
            Rejoignez-nous et transformez l'expérience scolaire de vos étudiants,
            avec une solution moderne et efficace pour optimiser leur parcours
            éducatif. Ensemble, faisons de l’éducation une aventure
            enrichissante et inspirante pour tous !
          </HeaderP>
          <BtnWrapper>
            <Link to="/register">
              <FullButton title="Commencer" />
            </Link>
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img
            className="radius8"
            src={HeaderImage}
            alt="office"
            style={{ zIndex: 9, marginRight: "10%" }}
          />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>
                  Gérez vos séances de soutien de manière simple et sans
                  tracas, pour une organisation optimale et un suivi efficace de
                  vos étudiants.
                </em>
              </p>
              <p
                className="font13 orangeColor textRight"
                style={{ marginTop: "10px" }}
              >
                Travaillons ensemble pour un avenir meilleur et une éducation de
                qualité pour tous.
              </p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        {/* <GreyDiv className="lightBg"></GreyDiv> */}
      </RightSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  min-height: 790px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    187deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(205, 216, 255, 1) 23%,
    rgba(129, 169, 255, 1) 70%
  );
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  margin-left: 70px;
  h1 {
    text-align: left;
  }
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
    h1 {
      text-align: center;
    }
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
    h1 {
      text-align: center;
    }
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  margin-right: 70px;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
    margin-right: 0px;
  }
`;
const HeaderP = styled.div`
  max-width: 670px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    max-width: 100%;
    margin-left: 15px;
    margin-right: 7px;
    text-align: left;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
    margin: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
