import { Modal, Button, Table } from "react-bootstrap";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { updateSession } from "../../Redux/groupSessions/groupSessions";

const EventDetailsModal = ({
  show,
  handleClose,
  DetailsSession,
  students,
  handleDeleteRepeating,
  handleDeleteSession,
  handleCheckboxChangeProf,
  teacherAbsent,
  handleStudentCheckboxChange,
  handleSaveAbsences,
  absentStudents,
}) => {


  const dispatch = useDispatch(); 
  const [showTextarea, setShowTextarea] = useState(false);
  const [description, setDescription] = useState(DetailsSession.description || "");


  useEffect(() => {
    if (DetailsSession) {
      setDescription(DetailsSession.description || "");
    }
  }, [DetailsSession]);

  const toggleTextarea = () => {
    setShowTextarea((prev) => !prev);
    if (showTextarea) {
      dispatch(updateSession({ id: DetailsSession.id, sessionData: { description: description?.toLowerCase() } }));

    }
  };


  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Détails de la Séance Groupe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="text-center mb-2" style={{background:"#0d6efd", color:"white" , borderRadius:"5px" , padding:"2px"}}>Details Séance</h5>
        <div  style={{height:"25vh" , overflowY:"hidden" }}>
        <Table striped bordered responsive>
          <thead>
            <tr className="text-center align-middle">
              <th>Jour</th>
              <th>Heure de début</th>
              <th>Heure de fin</th>
              <th>Prof</th>
              <th>{DetailsSession.room?.name? "Salle" :"type"} </th>
              <th>{teacherAbsent ? "Absente" : "Présent"} Prof</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center align-middle">
              <td>{moment(DetailsSession.start).format("YYYY-MM-DD")}</td>
              <td>{moment(DetailsSession.start).format("HH:mm")}</td>
              <td>{moment(DetailsSession.end).format("HH:mm")}</td>
              <td>
                {capitalizeFirstLetter(DetailsSession.teacher?.nom)} {capitalizeFirstLetter(DetailsSession.teacher?.prenom)}
              </td>
              <td>{DetailsSession.room?.name?.toUpperCase() || "à distance"}</td>
              <td className="text-center">
                <input
                  type="checkbox"
                  checked={teacherAbsent}
                  onChange={handleCheckboxChangeProf}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        </div>

        <div>
          <div className="d-flex justify-content-center align-items-center">
            <h5>Description</h5>
            <FaPlus
              className="fa-plus"
              style={{ marginBottom: "5px", color: "blue", size: "20px" }}
              onClick={toggleTextarea}
            />
          </div>
          {showTextarea && (
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4"
              className="form-control"
            />
          )}
          {!showTextarea && description && (
            <p className="text-muted mt-2">{description}</p>
          )}
          {showTextarea && !description && (
            <p className="text-muted">Aucune description fournie.</p>
          )}
        </div>

        <h6 className="text-center mb-3 mt-2" style={{background:"#0d6efd", color:"white" , borderRadius:"5px" , padding:"2px"}}>Étudiants inscrits</h6>
        {teacherAbsent ? (
          <p className="text-center">
            Le professeur est absent, donc la liste des étudiants ne peut pas
            être affichée.
          </p>
        ) : (
          <div style={{ overflowX: "auto", maxHeight: "180px" }}>
            <Table striped bordered hover>
              <thead>
                <tr className="text-center">
                  {/* <th>id</th> */}
                  <th>Étudiant</th>
                  <th>Absent</th>
                </tr>
              </thead>
              <tbody>
                {students.length > 0 ? (
                  students.map((student) => (
                    <tr key={student.student.id} className="text-center">
                      <td>{capitalizeFirstLetter(student.student.nom) +" "+ capitalizeFirstLetter(student.student.prenom)} </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={absentStudents.includes(student.student.id)}
                          onChange={() =>
                            handleStudentCheckboxChange(student.student.id)
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      Aucun étudiant inscrit
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <div>
          {DetailsSession.repeating_id !== null && (
              <Button
                variant="danger"
                className="m-2"
                size="sm"
                onClick={handleDeleteRepeating}
              >
                Supprimer séries
              </Button>
            )}
            <Button variant="danger" size="sm" onClick={handleDeleteSession} className="m-2">
              Supprimer ceci
            </Button>
          </div>
          <div className="text-center">
            <Button variant="primary" size="sm" onClick={handleSaveAbsences} className="m-2">
              Enregistrer les absences
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EventDetailsModal;
