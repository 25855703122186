import React from "react";
import styled from "styled-components";

// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo.png";
import { Link } from "react-scroll";
import { useLocation, matchPath, Link as RouterLink } from "react-router-dom";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const links = [
    { to: "accueil", label: "Accueil" },
    { to: "services", label: "Services" },
    { to: "tarifs", label: "Tarifs" },
    { to: "blog", label: "Blog" },
  ];

  const location = useLocation();

  const isLoginPage =
    location.pathname === "/register" ||
    location.pathname === "/login" ||
    location.pathname === "/contact" ||
    location.pathname === "/request-reset-password" ||
    matchPath("/verify-email/:token", location.pathname) !== null ||
    matchPath("/reset-password/:token", location.pathname) !== null;

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <img src={LogoIcon} alt="Logo" style={{ width: '60px', marginBottom: "5px" }} />
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        {!isLoginPage ? (
          links.map(({ to, label }) => (
            <li key={to} className="semiBold font15 pointer">
              <Link
                onClick={() => toggleSidebar(!sidebarOpen)}
                activeClass="active"
                className="whiteColor"
                style={{ padding: "10px 15px" }}
                to={to}
                spy={true}
                smooth={true}
                offset={-60}
                duration={200}
              >
                {label}
              </Link>
            </li>
          ))
        ) : (
          links.map(({ to, label }) => (
            <li key={to} className="semiBold font15 pointer">
              <RouterLink
                onClick={() => toggleSidebar(!sidebarOpen)}
                className="whiteColor"
                style={{ padding: "10px 15px" }}
                to="/"
              >
                {label}
              </RouterLink>
            </li>
          ))
        )}
      </UlStyle>

      <UlStyle className="flexSpaceCenter">
        <li className="semiBold font15 pointer">
          <RouterLink
            to="/login"
            style={{ padding: "10px 30px 10px 0" }}
            className="whiteColor"
          >
            Se Connecter
          </RouterLink>
        </li>
        <li className="semiBold font15 pointer flexCenter">
          <RouterLink
            to="/register"
            className="radius8 lightBg"
            style={{ padding: "10px 15px" }}
          >
            Commencer
          </RouterLink>
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  transition: right 0.3s ease; /* Transition smooth for opening/closing */

  @media (max-width: 400px) {
    width: 100%;
  }
`;

const SidebarHeader = styled.div`
  padding: 20px 0;
`;

const CloseBtn = styled.button`
  border: 0;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;

const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
