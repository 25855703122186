import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
    return (
        <Wrapper id="tarifs">
            <div className="whiteBg">
                <div className="container">
                    <HeaderInfo>
                        <h2 className="font40 extraBold">Consultez nos tarifs</h2>
                        <p className="font13 ">
                            Notre plateforme propose des prix compétitifs qui reflètent la véritable valeur des services que nous offrons.
                            <br />
                            Nous sommes engagés à développer constamment le site pour le rendre plus diversifié en services et plus facile à utiliser
                        </p>
                    </HeaderInfo>
                    <TablesWrapper className="flexSpaceNull">
                        <TableBox>
                            <PricingTable
                                icon="browser"
                                price={<PriceText>399Dh / Mois</PriceText>} 
                                title="Basique"
                                offers={[
                                    { name: "3 jours gratuits", cheked: true },
                                    { name: "Gestion des séances dans le calendrier professionnel", cheked: true },
                                    { name: "Gestion des étudiants et des professeurs de manière simple", cheked: true },
                                    { name: "Gestion des absences des professeurs et des étudiants", cheked: true },
                                    { name: "Gestion des prix des formations", cheked: true },
                                    { name: "Gestion des archives par année", cheked: true },
                                    { name: "Gestion des paiements des étudiants mensuellement", cheked: true },
                                    { name: "Possibilités d'ajouter d'autres comptes", cheked: true },
                                ]}
                            />
                        </TableBox>
                        <TableBox>
                            <PricingTable
                                icon="browser"
                                price={<PriceText>1999Dh / 6 Mois</PriceText>}
                                title="Standard"
                                offers={[
                                    { name: "3 jours gratuits", cheked: true },
                                    { name: "Gestion des séances dans le calendrier professionnel ", cheked: true },
                                    { name: "Gestion des étudiants et des professeurs de manière simple", cheked: true },
                                    { name: "Gestion des absences des professeurs et des étudiants", cheked: true },
                                    { name: "Gestion des prix des formations", cheked: true },
                                    { name: "Gestion des archives par année ", cheked: true },
                                    { name: "Gestion des paiements des étudiants mensuellement", cheked: true },
                                    { name: "Possibilités d'ajouter d'autres comptes", cheked: true },
                                ]}
                            />
                        </TableBox>
                        <TableBox>
                            <PricingTable
                                icon="browser"
                                price={<PriceText>3999Dh / An</PriceText>}
                                title="Premium"
                                offers={[
                                    { name: "3 jours gratuits", cheked: true },
                                    { name: "Gestion des séances dans le calendrier professionnel ", cheked: true },
                                    { name: "Gestion des étudiants et des professeurs de manière simple", cheked: true },
                                    { name: "Gestion des absences des professeurs et des étudiants", cheked: true },
                                    { name: "Gestion des prix des formations", cheked: true },
                                    { name: "Gestion des archives par année ", cheked: true },
                                    { name: "Gestion des paiements des étudiants mensuellement", cheked: true },
                                    { name: "Possibilités d'ajouter d'autres comptes", cheked: true },
                                ]}
                            />
                        </TableBox>
                    </TablesWrapper>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.section`
    width: 100%;
    padding: 50px 0;
`;

const HeaderInfo = styled.div`
    margin-bottom: 50px;
    @media (max-width: 860px) {
        text-align: center;
    }
`;

const TablesWrapper = styled.div`
    display: flex;
    @media (max-width: 860px) {
        flex-direction: column;
    }
`;

const TableBox = styled.div`
    width: 31%;
    @media (max-width: 860px) {
        width: 100%;
        max-width: 370px;
        margin: 0 auto;
    }
`;

const PriceText = styled.span`
    color:  #0B093B; 
    font-weight: bold;  
    font-size:35px;
`;
