import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

const initialState = {
  loading: false,
  error: null,
  success: false,
};

export const requestResetPassword = createAsyncThunk(
  "auth/requestResetPassword",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post("/request-reset-password", {
        email,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const formResetPassword = createAsyncThunk(
  "auth/formResetPassword",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/reset-password/${token}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ token, password, confirmation_password }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(`/reset-password/${token}`, {
        password,
        confirmation_password,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestResetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(requestResetPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(requestResetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(formResetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(formResetPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(formResetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearState } = authSlice.actions;

export default authSlice.reducer;
