import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProfessors,
  addProfessor,
  updateProfessor,
  deleteProfessor,
  setCurrentPage,
} from "../../Redux/Profs/professorsSlice";
import {
  Button,
  Form,
  Row,
  Col,
  Table,
  Pagination,
  Modal,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const Profs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedProf, setSelectedProf] = useState(null);
  const { professors, totalTeachers, currentPage, pageSize, status } =
    useSelector((state) => state.professors);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 3000);
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    phone: "",
    cin: "",
    birthday: "",
    email: "",
    country: "maroc",
    rib: "",
    city: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(
          fetchProfessors({
            page: currentPage,
            pageSize,
            searchTerm: debouncedSearchTerm,
          })
        ).unwrap();
      } catch (error) {
        const status = error?.status || error?.response?.status;
        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, currentPage, pageSize, debouncedSearchTerm, navigate]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(1));
    };
  }, [dispatch]); 

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    dispatch(setCurrentPage(1));
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const totalPages = Math.ceil(totalTeachers / pageSize);

  const validateForm = () => {
    const { nom, prenom, email, phone, rib, birthday, country, city } =
      formData;

    // Validate required fields
    if (
      !nom ||
      !prenom ||
      !phone ||
      !birthday ||
      !country ||
      !city
    ) {
      toast.error("Tous les champs sont obligatoires");
      return false;
    }

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailPattern.test(email)) {
      toast.error("Email invalide");
      return false;
    }

    // Validate phone number (assuming 10 digits)
    const phonePattern = /^[0-9]{10}$/;
    if (!phonePattern.test(phone)) {
      toast.error("Téléphone invalide");
      return false;
    }

    // Validate RIB (assuming 24 digits)
    const ribPattern = /^[0-9]{24}$/;
    if (rib && !ribPattern.test(rib)) {
      toast.error("RIB invalide");
      return false;
    }

    // Validate date of birth
    const today = new Date().toISOString().split("T")[0];
    if (new Date(birthday) > new Date(today)) {
      toast.error("Date de naissance n'est pas correct ");
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    // Convert names to lowercase
    const formattedData = {
      ...formData,
      nom: formData.nom.toLowerCase(),
      prenom: formData.prenom.toLowerCase(),
      country: formData.country.toLowerCase(),
      city: formData.city.toLowerCase(),
      email: formData.email ? formData.email.toLowerCase() : undefined, 
      cin: formData.cin ? formData.cin : undefined, 
    };

    setLoading(true);
    try {
      if (editMode) {
        await dispatch(
          updateProfessor({ id: selectedProf, updatedProfessor: formattedData })
        ).unwrap();
        toast.success("Professeur mis à jour");
      } else {
        await dispatch(addProfessor(formattedData)).unwrap();
        toast.success("Professeur ajouté");
      }
      dispatch(fetchProfessors({ page: currentPage, pageSize, searchTerm }));
      handleCloseModal();
    } catch (error) {
      const status = error?.status || error?.response?.status;
      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message);
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteProfessor(selectedProf)).unwrap();
      toast.success("Professeur supprimé");
      setShowDeleteModal(false);

      const updatedTotalTeachers = totalTeachers - 1;
      if (updatedTotalTeachers % pageSize === 0 && currentPage > 1) {
        dispatch(setCurrentPage(currentPage - 1));
      }

      dispatch(fetchProfessors({ page: currentPage, pageSize, searchTerm }));
    } catch (error) {
      const status = error?.status || error?.response?.status;
      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message);
          break;
      }
    }
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedProf(id);
    setShowDeleteModal(true);
  };

  const handleEdit = (prof) => {
    setFormData(prof);
    setSelectedProf(prof.id);
    setEditMode(true);
    setShowModal(true);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setFormData({
      nom: "",
      prenom: "",
      phone: "",
      cin: "",
      birthday: "",
      email: "",
      rib: "",
      city: "",
      country: "maroc",
    });
    setShowModal(false);
    setEditMode(false);
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  return (
    <>
      <Row className="align-items-center ">
        <Col xs={12} md={6} className="text-md-start text-center">
          <h3>Tableau des professeurs</h3>
        </Col>
      </Row>
      <Row className="align-items-center mb-2">
        <Col xs={12} md={4} className="text-md-start text-center ">
          <FaPlus
            className="fa-plus"
            style={{ fontSize: "20px", color: "blue", size: "20px" }}
            variant="primary"
            onClick={handleShowModal}
          >
            Ajouter
          </FaPlus>
        </Col>
        <Col xs={12} md={4} className="mb-md-0 d-flex justify-content-center">
          <Form.Control
            type="text"
            placeholder="Rechercher par Nom, Prenom, Phone, CIN"
            style={{ width: "100%" }}
            size="sm"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>

      <div className="table-responsive-etud">
        <Table bordered size="sm" responsive>
          <thead>
            <tr className="text-center">
              <th>N</th>
              <th>Nom</th>
              <th>Prenom</th>
              <th>Telephone</th>
              <th>CIN</th>
              <th>Date Naissance</th>
              <th>Email</th>
              <th>RIB</th>
              <th>Pays</th>
              <th>Ville</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {status === "loading" ? (
              <tr>
                <td colSpan="11" className="text-center">
                  <Spinner animation="border" role="status" />
                  <span className="ms-2">Chargement...</span>
                </td>
              </tr>
            ) : status === "error" ? (
              <tr>
                <td colSpan="11" className="text-center">
                  Une erreur s'est produite lors du chargement.
                </td>
              </tr>
            ) : professors.length > 0 ? (
              professors.map((prof, index) => (
                <tr key={prof.id} className="text-center">
                  <td>{index + 1}</td>
                  <td>{capitalizeFirstLetter(prof.nom)}</td>
                  <td>{capitalizeFirstLetter(prof.prenom)}</td>
                  <td>{prof.phone}</td>
                  <td>{prof?.cin?.toUpperCase() ||"Non existant"}</td>
                  <td>
                    {prof.birthday
                      ? moment(prof.birthday).format("YYYY/MM/DD")
                      : "Non disponible"}
                  </td>
                  <td>{prof.email ||"Non existant"}</td>
                  <td>{prof.rib || "Non existant"}</td>
                  <td>
                    {prof.country
                      ? capitalizeFirstLetter(prof.country)
                      : "Non disponible"}
                  </td>
                  <td>
                    {prof.city
                      ? capitalizeFirstLetter(prof.city)
                      : "Non disponible"}
                  </td>
                  <td className="text-center">
                    <FaTrash
                      className="m-2"
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleDeleteConfirmation(prof.id)}
                    />
                    <FaEdit
                      className="m-2"
                      style={{ color: "green", cursor: "pointer" }}
                      onClick={() => handleEdit(prof)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" className="text-center">
                  Aucun Professeur trouvé.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-center">
        <Col xs="auto">
          <Pagination>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Col>
      </Row>

      <Pagination className="justify-content-center"></Pagination>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Modifier Professeur" : "Ajouter Professeur"}
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Row className="mb-2">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Nom<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="nom"
                    value={formData.nom}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Prénom<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="prenom"
                    value={formData.prenom}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Téléphone<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>CIN</Form.Label>
                  <Form.Control
                    type="text"
                    name="cin"
                    value={formData.cin}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Date de Naissance<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    type="date"
                    name="birthday"
                    value={formData.birthday}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Pays<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>RIB</Form.Label>
                  <Form.Control
                    type="text"
                    name="rib"
                    value={formData.rib}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Ville<span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleSave}
              disabled={loading}
              className="mr-auto"
            >
              {loading ? "Enregistrement..." : "Enregistrer"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmer la Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer ce professeur ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
