import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

export const fetchFormations = createAsyncThunk(
  'formations/fetchFormations',
  async ({ categorie = '', page = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/formations${categorie ? `/${categorie}` : ''}`, {
        params: { page, pageSize  , categorie},
      });
      return response.data;  
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);



export const fetchFormationsTeaching = createAsyncThunk(
  "formations/fetchFormationsTeaching",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/formations`);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const addFormation = createAsyncThunk(
  "formations/addFormation",
  async (formationData, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post("/formations", formationData);
      return response.data; 
    }catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const updateFormation = createAsyncThunk(
  "formations/updateFormation",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`/formations/${id}`, updatedData);
      return response.data; 
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const deleteFormation = createAsyncThunk(
  "formations/deleteFormation",
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/formations/${id}`);
      return id; 
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const fetchFormationsWithCategories = createAsyncThunk(
  'formations/fetchFormationsWithCategories',
  async ({ searchTerm = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get('/formations-with-categories', {
        params: { searchTerm }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);


// Create the slice
const formationSlice = createSlice({
  name: "formations",
  initialState: {
    formations: [],
    formationsWithCategories: [],
    status: "idle",
    error: null,
    totalFormations: 0,
    currentPage: 1,
    pageSize: 10,
  },
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFormations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.formations = action.payload.formations;
        state.totalFormations  = action.payload.totalFormations;
      })
      .addCase(fetchFormations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload; 
      })
      .addCase(addFormation.fulfilled, (state, action) => {
        state.formations.push(action.payload); 
      })
      .addCase(addFormation.rejected, (state, action) => {
        state.error = action.payload; 
      })
      .addCase(updateFormation.fulfilled, (state, action) => {
        const { id, updatedData } = action.payload;
        const index = state.formations.findIndex((formation) => formation.id === id);
        if (index !== -1) {
          state.formations[index] = {
            ...state.formations[index],
            ...updatedData,
          }; 
        }
      })
      .addCase(updateFormation.rejected, (state, action) => {
        state.error = action.payload; 
      })
      .addCase(deleteFormation.fulfilled, (state, action) => {
        const id = action.payload;
        state.formations = state.formations.filter((formation) => formation.id !== id); 
      })
      .addCase(deleteFormation.rejected, (state, action) => {
        state.error = action.payload; 
      })
      .addCase(fetchFormationsWithCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFormationsWithCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.formationsWithCategories = action.payload;
      })
      .addCase(fetchFormationsWithCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});


export const {  setCurrentPage } =
formationSlice.actions;

export default formationSlice.reducer;
